import { TabsProps as AntdTabsProps } from "antd";
import { Guid } from "guid-typescript";
import _, { isEmpty } from "lodash";
import Tabs from "rc-tabs";
import "rc-tabs/assets/index.css";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

declare let window: any;
interface TabProps extends AntdTabsProps {
  activeKey: string;
  defaultActiveKey: string;
  onChange?: (activeKey: string) => any;
  value?: string | number;
  selectedTabColor?: string;
  className?: string;
  children?: any;
}

interface TabState {
  uniqueKey: Guid;
}

class Tab extends PureComponent<TabProps & CommonProps, TabState> {
  private memoizedDynamicCssResult = "";

  shouldPreventOnChangeFunction: boolean = false;

  constructor(props: TabProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();

    const children = this.getChildren();
    if (children.length) {
      let activeIndex = 0;
      if (this.props.value) {
        activeIndex = children.findIndex(
          (tabItem) => tabItem.key === this.props.value || tabItem.props?.kuikaid === this.props.value
        );
        if (activeIndex === -1) activeIndex = 0;
      }

      if (children[activeIndex].props?.onActive) {
        children[activeIndex].props.onActive();
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<TabProps & CommonProps>, prevState: Readonly<TabState>, snapshot?: any): void {
    if (window.kuika?.isDesignTime) return;

    if (this.props.value !== prevProps.value) {
      if (this.shouldPreventOnChangeFunction) {
        if (this.props.onChange) this.shouldPreventOnChangeFunction = false;
      } else {
        if (this.props.onChange) this.props.onChange(`${this.props.value}`);
        this.handleTabItemOnActive(this.props.value);
      }
    }
  }

  handleOnChangeTab = (activeTabKey: any, setState: boolean) => {
    if (this.props.onChange) {
      this.shouldPreventOnChangeFunction = true;
      this.props.onChange(activeTabKey);
    }

    this.handleTabItemOnActive(activeTabKey);
  };

  handleTabItemOnActive = (activeTabKey) => {
    const activeChild = this.props.children?.find(
      (tabItem) => (tabItem.key === activeTabKey || tabItem.props?.kuikaid === activeTabKey) && tabItem.props?.onActive
    );
    if (!activeChild) return;

    activeChild.props.onActive();
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { selectedTabColor, tabPosition } = this.props;
    if (selectedTabColor) {
      result += `.${className.trim()} .rc-tabs-tab-active, .rc-tabs-tab-active:active, .rc-tabs-tab-active:hover, .rc-tabs-tab-active:focus, .rc-tabs-tab:hover, .rc-tabs-tab:focus, .rc-tabs-tab:active {
          color: ${this.props.selectedTabColor} !important;
        }`;
      result += `.${className.trim()} .rc-tabs-tab-disabled, .rc-tabs-tab-disabled:hover, .rc-tabs-tab-disabled:active, .rc-tabs-tab-disabled:focus {
          color: #ccc !important;
        }`;
      result += `.${className.trim()} .rc-tabs-tab {
          transition: none !important;
        }`;
      result += `.${className.trim()} .rc-tabs-ink-bar {
          background: ${this.props.selectedTabColor};
        }`;
    }
    result += `.${className.trim()} .rc-tabs-tabpane {
        padding: 0px !important;
        overflow: hidden !important;
    }`;
    result += `.${className.trim()} .rc-tabs-tab {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }`;

    result += `.${className.trim()} {
      display: block !important;
      align-items: unset !important;
    }`;

    result += `.${className.trim()} .rc-tabs-bar {
      margin: 0 0 16px !important;
      border-color: transparent !important;
    }`;

    result += `.${className.trim()}.rc-tabs-left .rc-tabs-tab {
      padding-right: 8px !important;
    }`;

    result += `.${className.trim()}.rc-tabs-right .rc-tabs-tab {
      padding-left: 8px !important;
    }`;

    result += `.${className.trim()}.rc-tabs-left .rc-tabs-bar {
      margin-right: 16px !important;
    }`;

    result += `.${className.trim()}.rc-tabs-right .rc-tabs-bar {
      margin-left: 16px !important;
    }`;

    result += `.${className.trim()}.rc-tabs-bottom .rc-tabs-bar {
      margin-top: 16px !important;
    }`;

    result += `.${className.trim()} .rc-tabs-tabpane-active {
      padding: 0px !important;
    }`;

    result += `.${className.trim()}.rc-tabs-right .rc-tabs-nav-scroll,
    .${className.trim()}.rc-tabs-left .rc-tabs-nav-scroll {
      height: auto;
    }`;

    result += `.${className.trim()}.rc-tabs-left,
    .${className.trim()}.rc-tabs-right {
      display: flex !important;
    }`;

    result += `.${className.trim()}.rc-tabs-right {
      flex-direction: row-reverse;
    }`;

    result += `.${className.trim()} .rc-tabs-content.rc-tabs-content-animated {
      flex: 1;
    }`;

    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} ktab_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    let props = {} as any;
    props = _.clone(this.props);
    if (props.activeKey) {
      delete props.activeKey;
    }
    if (props.defaultActiveKey) {
      delete props.defaultActiveKey;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props.children) {
      delete props.children;
    }
    if (props.selectedTabColor) {
      delete props.selectedTabColor;
    }

    props.tabBarPosition = props.tabPosition || "top";
    delete props.tabPosition;

    return props;
  };

  getChildren() {
    if (!this.props.children.length) {
      if (this.props.children.props.visibility !== "hidden") {
        return [this.props.children];
      }
      return [];
    }
    const returnValue = [];
    this.props.children.map((child) => {
      if (child.props.visibility !== "hidden") {
        const _child = {
          ...child,
          props: {
            ...child.props,
            disabled: child.props.editability === "disabled"
          }
        };
        returnValue.push(_child);
      }
    });
    return returnValue;
  }

  getActiveKey = () => {
    let activeKey = this.props.value;
    let keyProp = "id";
    if (window.kuika?.dashboardState === 1) {
      activeKey = this.props.activeKey;
      keyProp = "kuikaid";
    }

    if (
      isEmpty(activeKey) &&
      this.props.children &&
      this.props.children[0] &&
      this.props.children[0].props &&
      this.props.children[0].props[keyProp]
    ) {
      activeKey = this.props.children[0].props[keyProp];
    }

    return activeKey;
  };

  render(): ReactNode {
    let activeKey = this.getActiveKey();
    return (
      <Tabs
        animated={false}
        {...this.getProps()}
        className={this.getClassName()}
        onChange={this.handleOnChangeTab}
        activeKey={activeKey}
        defaultActiveKey={this.props.defaultActiveKey}
        renderTabBar={() => <ScrollableInkTabBar />}
        renderTabContent={() => <TabContent />}
      >
        {this.getChildren()}
      </Tabs>
    );
  }
}

const tab = withCommonEvents(Tab);
export { tab as Tab };
