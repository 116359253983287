import { PureComponent, ReactNode } from "react";

interface CarouselViewWrapperProps {
  style?: any;
}

interface CarouselViewWrapperState {}

export class CarouselViewWrapper extends PureComponent<CarouselViewWrapperProps, CarouselViewWrapperState> {
  constructor(props: CarouselViewWrapperProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
