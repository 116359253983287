import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { PureComponent } from "react";
import { IStripePayment } from "../../../models/dto/stripe-payment";
import { STRIPE_SETTINGS } from "../../../shared/utilty/constants";
import { CommonProps } from "../common-props";
import "./styles.css";

declare let window: any;

interface StripeWrapperProps {
  stripeAction?: IStripePayment;
  onSuccess?: any;
  onError?: any;
  callback?: any;
  screen?: any;
  futureUse?: any;
}

interface StripeWrapperState {
  stripePromise: any;
  isLoaded: any;
  isPaymentAcceptable: boolean;
}

export class PaymentElementStripe extends PureComponent<StripeWrapperProps & CommonProps, StripeWrapperState> {
  constructor(props: StripeWrapperProps) {
    super(props);
    this.state = {
      stripePromise: undefined,
      isLoaded: false,
      isPaymentAcceptable: false
    };
  }

  componentDidMount() {
    let loadStripeKey = "";
    if (window.kuika?.dashboardState === 1) {
      // Designer
      loadStripeKey =
        "pk_test_51KQQy1D08MhpLIYNiHJlPjfxplHwO9ytq2nQ3MEvcFjcuL1UTGYAtEDlaAAbwnf1bKxRK4tCiEU8vcVIhw96Z4ei00sq3gLJAv";
    } else if (STRIPE_SETTINGS.publishKey) {
      loadStripeKey = STRIPE_SETTINGS.publishKey;
    }
    const stripePromise = loadStripe(loadStripeKey);
    this.setState({ stripePromise, isLoaded: true });
  }

  confirmPayment = async (
    event: any,
    ctx: any,
    onSuccess?: any,
    onError?: any,
    callback?: any,
    screen?: any,
    paymentIntent?: string
  ) => {
    event.preventDefault();
    const { stripe } = ctx;
    const { elements } = ctx;
    if (this.props.futureUse) {
      await stripe
        .confirmSetup({
          elements,
          redirect: "if_required"
        })
        .then((result) => {
          if (!result.error) {
            if (screen) {
              screen.setState(
                {
                  SetupIntent: {
                    isSuccess: true,
                    errorMessage: "",
                    paymentMethod: result.setupIntent.payment_method,
                    seti: result.setupIntent.id
                  }
                },
                () => {
                  screen.props.hideStripeDrawer();
                  if (callback) {
                    callback();
                  }
                }
              );
            }
          } else if (screen) {
            screen.setState(
              {
                SetupIntent: {
                  isSuccess: false,
                  errorMessage: result.error.message,
                  paymentMethod: undefined,
                  seti: undefined
                }
              },
              () => {
                screen.props.hideStripeDrawer();
                if (callback) {
                  callback();
                }
              }
            );
          }
        });
    } else {
      await stripe
        .confirmPayment({
          elements,
          redirect: "if_required"
        })
        .then((result) => {
          if (!result.error) {
            if (screen) {
              if (screen) {
                screen.setState(
                  {
                    OpenStripePaymentDrawer: {
                      isSuccess: true,
                      errorMessage: "",
                      paymentIntent: paymentIntent?.substring(0, paymentIntent.indexOf("_secret"))
                    }
                  },
                  () => {
                    screen.props.hideStripeDrawer();
                    if (callback) {
                      callback();
                    }
                  }
                );
              }
            }
          } else if (screen) {
            screen.setState(
              {
                OpenStripePaymentDrawer: {
                  isSuccess: false,
                  errorMessage: result.error.message,
                  paymentIntent: undefined
                }
              },
              () => {
                screen.props.hideStripeDrawer();
                if (callback) {
                  callback();
                }
              }
            );
          }
        });
    }
  };

  getButtonLabel = () => {
    const language = localStorage.getItem("ml");
    let retValue = "";

    switch (language) {
      case "fr_FR":
        retValue = this.props.futureUse ? "Enregistrer" : "Payer";
        break;

      case "tr_TR":
        retValue = this.props.futureUse ? "Kaydet" : "Öde";
        break;
      case "en_US":
      default:
        retValue = this.props.futureUse ? "Save" : "Buy";
        break;
    }
    return retValue;
  };

  renderCard = (ctx) => {
    return (
      <>
        <PaymentElement
          onChange={(e) => {
            this.setState({ isPaymentAcceptable: e.complete });
          }}
          id="payment-element"
        />
        <button
          disabled={!this.state.isPaymentAcceptable}
          className="kuika-stripe-payment-button"
          onClick={(e) =>
            this.confirmPayment(
              e,
              ctx,
              this.props.onSuccess,
              this.props.onError,
              this.props.callback,
              this.props.screen,
              this.props.stripeAction?.clientSecret
            )
          }
        >
          {this.getButtonLabel()}
        </button>
      </>
    );
  };

  render() {
    let clientSecret = this.props.stripeAction?.clientSecret;
    if (window.kuika?.dashboardState === 1) {
      // Designer
      clientSecret = "pi_3KYAHvD08MhpLIYN0nFFlYYq_secret_pOIjXrIhtqj6REVsgRPvzwHnS";
    }
    if (this.state.stripePromise && clientSecret && clientSecret !== "") {
      const { stripePromise } = this.state;
      return (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <ElementsConsumer>{(ctx: any) => this.renderCard(ctx)}</ElementsConsumer>
        </Elements>
      );
    }
    return <></>;
  }
}
