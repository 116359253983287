import { Component } from "react";

class AutoComplete extends Component<any, any> {
  autoComplete: any;

  searchInput: any;

  constructor(props) {
    super(props);
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount() {
    const { map } = this.props;
    const { maps } = this.props;
    const options = {
      types: ["address"]
    };
    this.autoComplete = new maps.places.Autocomplete(this.searchInput, options);
    this.autoComplete.addListener("place_changed", this.onPlaceChanged);
    this.autoComplete.bindTo("bounds", map);
  }

  componentWillUnmount() {
    const { maps } = this.props;
    maps.event.clearInstanceListeners(this.searchInput);
  }

  onPlaceChanged = ({ map, addplace } = this.props) => {
    const place = this.autoComplete.getPlace();
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      // map.setZoom(17);
      if (this.props.onChangeLocation) {
        this.props.onChangeLocation(place.geometry.location);
      }
    }
    addplace(place);
    if (this.searchInput) {
      this.searchInput.blur();
    }
  };

  clearSearchBox() {
    if (this.searchInput) {
      this.searchInput.value = "";
    }
  }

  getDisplay = () => {
    if (this.props.map !== undefined && this.props.maps !== undefined && this.props.hasSearchBox === true) {
      return {};
    }
    return { display: "none" };
  };

  render() {
    return (
      <>
        <input
          style={{ position: "absolute", zIndex: 999, left: 25, top: 10, ...this.getDisplay() }}
          className="form-control marb15"
          ref={(ref) => {
            this.searchInput = ref;
          }}
          required
          name="propertyGPS"
          type="text"
          onFocus={this.clearSearchBox}
        />
      </>
    );
  }
}

export default AutoComplete;
