import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { BaseChart, BaseChartProps } from "../base-chart";

class LineChart extends BaseChart<any, any> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "line"
  };
}

const lineChart = withCommonEvents(LineChart);
export { lineChart as LineChart };
