import { Menu } from "antd";
import { Guid } from "guid-typescript";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";
import { Icon } from "../../icon/icon";

interface ContentItemProps {
  iconName?: string;
  onClick?: () => void;
  label: string;
}

interface ContentItemState {}

class ContentMenuItem extends PureComponent<ContentItemProps & CommonProps, ContentItemState> {
  key = Guid.create().toString();

  constructor(props: ContentItemProps) {
    super(props);
    this.state = {};
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render(): ReactNode {
    let iconProp = {};
    if (this.props.iconName) {
      iconProp = { icon: <Icon iconName={this.props.iconName} /> };
    }

    return (
      <Menu.Item {...this.props} onClick={this.handleClick} key={this.key} {...iconProp}>
        {this.props.label}
      </Menu.Item>
    );
  }
}

const contentMenuItem = withCommonEvents(ContentMenuItem);
export { contentMenuItem as ContentMenuItem };
