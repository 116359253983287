import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { BaseChart, BaseChartProps } from "../base-chart";

class BarChart extends BaseChart<any, any> {
  constructor(props: BaseChartProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "bar"
  };
}

const barChart = withCommonEvents(BarChart);
export { barChart as BarChart };
