import { Drawer, Layout } from "antd";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import supercluster from "points-cluster";
import React, { CSSProperties, DetailedReactHTMLElement, PureComponent, ReactNode } from "react";
import { Icon } from "../../../icon/icon";
import { toCamelCase } from "../../../kuika-cl-model-runtimes";
import AutoComplete from "./google-map-search";
import { ICoords, MarkerDetailPosition, MarkerDetailView } from "./google-maps-item";

declare let google: any;
declare let window: any;
interface IMarker {
  label?: string;
  img?: string;
  icon?: string;
  lat: number;
  lng: number;
}

export interface GoogleMapsBodyWrapperProps {
  value?: ICoords;
  style?: any;
  latitude?: string;
  longitude?: string;
  zoom?: number;
  isPicker?: boolean;
  disableDoubleClickZoom?: boolean;
  fullscreenControl?: boolean;
  streetViewControl?: boolean;
  scaleControl?: boolean;
  zoomControl?: boolean;
  panControl?: boolean;
  rotateControl?: boolean;
  mapTypeControl?: boolean;
  isMarkerPicker?: boolean;
  hasSearchBox?: boolean;
  autoCenter?: boolean;
  onChange?: (isDataMarkerSelected: boolean, value?: ICoords) => void;
  onGoogleApiLoaded?: (map: any, maps: any) => void;
  maps?: any;
  map?: any;
  circleRadius?: number;
  isDataMarkerSelected: boolean;
  dataLatField: string;
  dataLngField: string;
  activeContent?: any;
  clearActiveContent?: () => void;
  markerDetailView?: MarkerDetailView;
  markerDetailPosition?: MarkerDetailPosition;
  markerDetailHeight?: number;
  markerDetailWidth?: number;
  designClicked?: boolean;
  groupPickerIcon?: string;
  groupPickerColor?: string;
  currentLocationIcon?: string;
  currentLocationColor?: string;
}

interface GoogleMapsBodyWrapperState {
  draggable: boolean;
  latitude?: number;
  longitude?: number;
  radius?: any;
  zoom?: number;
  bounds?: any;
  isGroupPickerDetailsOpen: boolean;
  groupPickerDetails: DetailedReactHTMLElement<any, HTMLElement>[];
  userCoords?: ICoords;
}

export class GoogleMapsBodyWrapper extends PureComponent<GoogleMapsBodyWrapperProps, GoogleMapsBodyWrapperState> {
  mapsLayout = React.createRef<HTMLDivElement>();

  constructor(props: GoogleMapsBodyWrapperProps) {
    super(props);
    this.state = {
      draggable: true,
      isGroupPickerDetailsOpen: false,
      groupPickerDetails: []
    };

    this.setUserCoords();
  }

  public static defaultProps = {
    isDataMarkerSelected: false,
    hasSearchBox: true,
    isPicker: true,
    disableDoubleClickZoom: false,
    fullscreenControl: true,
    streetViewControl: true,
    scaleControl: true,
    zoomControl: true,
    panControl: true,
    rotateControl: true,
    mapTypeControl: true,
    autoCenter: false,
    dataLatField: "lat",
    dataLngField: "lng"
  };

  static getDerivedStateFromProps(nextProps: GoogleMapsBodyWrapperProps, prevState: GoogleMapsBodyWrapperState) {
    if (nextProps.autoCenter !== true) {
      return {
        latitude: undefined,
        longitude: undefined
      };
    }
  }

  convertToCoordsFieldsFromLatField = (record: any): number | undefined => {
    if (this.props.dataLatField === undefined) return undefined;
    if (this.props.dataLatField !== undefined && record[toCamelCase(this.props.dataLatField)] !== undefined) {
      const latitude = parseFloat(record[toCamelCase(this.props.dataLatField)]);
      return latitude;
    }
  };

  convertToCoordsFieldsFromLngField = (record: any): number | undefined => {
    if (this.props.dataLngField === undefined) return undefined;
    if (this.props.dataLngField !== undefined && record[toCamelCase(this.props.dataLngField)] !== undefined) {
      const longitude = parseFloat(record[toCamelCase(this.props.dataLngField)]);
      return longitude;
    }
  };

  componentDidMount() {
    if (this.props.latitude !== undefined && this.props.longitude !== undefined) {
      return;
    }
    this.setCenter();
  }

  setCenter = () => {
    const formattedValue: ICoords | undefined = this.props.value;
    if (
      formattedValue === undefined ||
      formattedValue.latitude === undefined ||
      formattedValue.longitude === undefined ||
      formattedValue.latitude <= 0 ||
      formattedValue.longitude <= 0
    ) {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          const { coords } = pos;
          if (coords.latitude && coords.longitude) {
            this.setState({ latitude: coords.latitude, longitude: coords.longitude }, () => {
              if (this.props.onChange) {
                let { value } = this.props;
                if (value === undefined) {
                  value = { latitude: coords.latitude, longitude: coords.longitude };
                  this.props.onChange(this.props.isDataMarkerSelected, value);
                }
              }
            });
          }
        });
      }
    } else if (this.props.value) {
      this.setState({ latitude: formattedValue.latitude, longitude: formattedValue.longitude });
    }
  };

  getCenter = (): IMarker => {
    return { lat: this.getLatitude(), lng: this.getLongitude() } as IMarker;
  };

  getLatitude = () => {
    if (this.state.latitude !== undefined) {
      return this.state.latitude;
    }
    if (this.props.latitude !== undefined) {
      // @ts-ignore
      if (!isNaN(this.props.latitude)) return parseFloat(this.props.latitude);
    }
    return 41.0049823;
  };

  getLongitude = () => {
    if (this.state.longitude !== undefined) {
      return this.state.longitude;
    }
    if (this.props.longitude !== undefined) {
      // @ts-ignore
      if (!isNaN(this.props.latitude)) return parseFloat(this.props.longitude);
    }
    return 28.7320004;
  };

  getZoom = () => {
    if (this.state.zoom !== undefined) {
      return this.state.zoom;
    }
    if (this.props.zoom !== undefined) {
      return this.props.zoom;
    }
    return 11;
  };

  /**
   * Calculates distance between 2 coordinates by using **Haversine Formula**
   * @param crd1 First coordinate
   * @param crd2 Second coordinate
   */
  calculateDistance(crd1: IMarker, crd2: IMarker) {
    const EARTH_RADIUS = 6371;
    const dLat = this.deg2Rad(crd1.lat - crd2.lat);
    const dLng = this.deg2Rad(crd1.lng - crd2.lng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2Rad(crd1.lat)) * Math.cos(this.deg2Rad(crd2.lat)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return EARTH_RADIUS * c;
  }

  deg2Rad(dif: number) {
    return dif * (Math.PI / 180);
  }

  calculateGroupPickerDrawerWidth() {
    let drawerWidth = 378;
    const { markerDetailWidth } = this.props;

    if (markerDetailWidth) {
      drawerWidth = markerDetailWidth > drawerWidth ? markerDetailWidth : drawerWidth;
      if (this.mapsLayout.current) {
        const halfWidthOfMap = this.mapsLayout.current.offsetWidth / 2;
        if (halfWidthOfMap < drawerWidth) {
          drawerWidth = halfWidthOfMap;
        }
      }
    }

    return drawerWidth + 32;
  }

  handleClickDataMarker = (marker: IMarker) => {
    if (marker && marker.lat && marker.lng && this.props.isMarkerPicker === true) {
      const value = { latitude: marker.lat, longitude: marker.lng } as ICoords;
      this.generateAddress(marker.lat, marker.lng);
      if (this.props.onChange) {
        this.props.onChange(true, value);
        // this.props.onChange(true, value); //Seçili olan item haritada ortalansın.
      }
    }
  };

  handleMarkerPickerclick = (marker: IMarker) => {
    if (this.isMarkerMouseOver === false && marker && marker.lat && marker.lng && this.props.isPicker === true) {
      const value = { latitude: marker.lat, longitude: marker.lng } as ICoords;
      this.generateAddress(marker.lat, marker.lng);
      if (this.props.onChange) {
        this.props.onChange(false, value);
        // this.props.onChange(false, value); //Seçili olan yer haritada ortalansın.
      }
    }
  };

  handleDrawRadius(gMaps?) {
    const map = gMaps?.map || this.props.map;
    const maps = gMaps?.maps || this.props.maps;
    const circleRadius = this.props.circleRadius;

    if (!map || !maps || !circleRadius) return;

    if (this.state.radius) this.state.radius.setMap(null);

    const radius = new maps.Circle({
      strokeColor: "rgba(3, 88, 255, 100)",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "rgba(3, 88, 255, 100)",
      fillOpacity: 0.3,
      map,
      center: this.getCenter(),
      radius: circleRadius * 1000
    });

    this.setState({ radius });
  }

  handleGoogleApiLoaded = (map, maps) => {
    if (this.props.onGoogleApiLoaded) {
      this.props.onGoogleApiLoaded(map, maps);
      this.handleDrawRadius({ map, maps });
    }
  };

  handleChangeStatus = ({ center, zoom, bounds }) => {
    center.lat = Number(center.lat.toFixed(6));
    center.lng = Number(center.lng.toFixed(6));
    // if (Number(this.getLatitude().toFixed(6)) === center.lat || Number(this.getLongitude().toFixed(6)) === center.lng) {
    //   return;
    // }
    this.setState({ latitude: center.lat, longitude: center.lng, zoom, bounds });
    this.handleDrawRadius();
  };

  getStyleProp = () => {
    let style: any = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (!style.height) {
      style.height = "400px";
    }
    if (!style.width) {
      style.width = "100%";
    }
    if ((style.display = "inline")) {
      style.display = "inline-flex";
    }
    return style;
  };

  handleMarkerInteraction = () => {
    this.setState({
      draggable: false
    });
  };

  isMarkerMouseOver: boolean = false;

  handleMarkerInteractionMouseOver = () => {
    this.isMarkerMouseOver = true;
  };

  handleMarkerInteractionMouseOut = () => {
    this.isMarkerMouseOver = false;
  };

  selectPlace = (place) => {
    this.handleMarkerPickerclick({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      formattedAddress: place.formatted_address,
      label: place.name
    } as IMarker);
  };

  pickerRender = (value?: ICoords) => {
    if (value !== undefined && value.latitude !== undefined && value.longitude !== undefined) {
      return (
        <div
          style={{ zIndex: 99 }}
          className="marker-style"
          // @ts-ignore
          lat={value.latitude}
          lng={value.longitude}
        ></div>
      );
    }
    return <></>;
  };

  getCircleCenterRadius() {
    const { isMarkerPicker, circleRadius } = this.props;
    if (!isMarkerPicker || !circleRadius) return <></>;

    const { lat, lng } = this.getCenter();
    return (
      <div
        style={{
          position: "relative"
        }}
        // @ts-ignore
        lat={lat}
        lng={lng}
      >
        <span
          style={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            width: 8,
            height: 8,
            borderRadius: "100%",
            backgroundColor: "rgba(3, 88, 255, 100)"
          }}
        ></span>
      </div>
    );
  }

  setUserCoords() {
    if (window.kuika?.isDesignTime || !("geolocation" in navigator)) return;
    const that = this;
    navigator.geolocation.watchPosition(
      function ({ coords: { latitude, longitude, accuracy } }) {
        const userCoords = that.state.userCoords;

        // Prevent updating if distance between previous location and new location is less than 1 meter
        if (userCoords) {
          if (
            that.calculateDistance(
              { lat: latitude, lng: longitude },
              { lat: userCoords.latitude, lng: userCoords.longitude }
            ) < 0.001 ||
            userCoords.accuracy < accuracy
          )
            return;
        }

        that.setState({ userCoords: { latitude, longitude, accuracy } });
      },
      () => {},
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 10000 }
    );
  }

  getCurrentLocation() {
    const { currentLocationIcon, currentLocationColor } = this.props;

    if (window.kuika?.isDesignTime || !currentLocationColor || !this.state.userCoords) return <></>;

    const iconStyles: React.CSSProperties = {
      color: currentLocationColor,
      transform: "translate(-50%, -50%)"
    };
    let iconElement: JSX.Element;

    if (!currentLocationIcon) {
      iconStyles.width = 20;
      iconStyles.height = 20;
      iconStyles.background = currentLocationColor;
      iconStyles.borderRadius = "100%";

      iconElement = <div style={iconStyles}></div>;
    } else {
      iconElement = <Icon iconName={currentLocationIcon} style={iconStyles} />;
    }

    return (
      <div
        // @ts-ignore
        lat={this.state.userCoords.latitude}
        lng={this.state.userCoords.longitude}
      >
        {iconElement}
      </div>
    );
  }

  generateAddress(lat, lng) {
    if (!this.props.map || !this.props.maps) {
      return;
    }
    const { maps } = this.props;
    const geocoder = new maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      // if (status === "OK") {
      //   if (results[0]) {
      //     // if (this.state.formatted_address !== results[0].formatted_address) {
      //     //   // this.setState({
      //     //   //   // formatted_address: results[0].formatted_address
      //     //   // });
      //     // }
      //   }
      // }
    });
  }

  handleCloseGroupPickerDetails() {
    this.setState({ isGroupPickerDetailsOpen: false, groupPickerDetails: [] });
  }

  handleOpenGoupPickerDetails(details: DetailedReactHTMLElement<any, HTMLElement>[]) {
    this.setState({ isGroupPickerDetailsOpen: true, groupPickerDetails: details });
  }

  handleRenderGroupPickerDetails() {
    if (this.state.groupPickerDetails.length === 0) return undefined;

    const firstMarker = React.cloneElement(
      this.state.groupPickerDetails[0],
      this.state.groupPickerDetails[0].props,
      this.state.groupPickerDetails[0].props?.children
    );
    const firstMarkerPopupContent = firstMarker.props
      .rowComponent(firstMarker.props)
      .props.rowPopoverRenderer(undefined, firstMarker.props.record, firstMarker.props.index);

    if (!firstMarkerPopupContent?.props?.children?.props?.children) return undefined;

    return this.state.groupPickerDetails.map((child: DetailedReactHTMLElement<any, HTMLElement>, i) => {
      const element = React.cloneElement(child, child.props, child.props?.children);
      return (
        <div
          style={{
            padding: "12px 16px",
            borderTop: i !== 0 ? "2px solid #ccc" : undefined
          }}
        >
          {element.props
            .rowComponent(element.props)
            .props.rowPopoverRenderer(undefined, element.props.record, element.props.index)}
        </div>
      );
    });
  }

  getClusters(markerElements: any[]) {
    const markersData = [];
    for (let i = 0; i < markerElements.length; i++) {
      const markerElement = markerElements[i];
      if (!markerElement.props && markerElement.props?.record) continue;

      const lat = this.convertToCoordsFieldsFromLatField(markerElement?.props?.record);
      const lng = this.convertToCoordsFieldsFromLngField(markerElement?.props?.record);

      if (!Number.isNaN(lat) && !Number.isNaN(lng) && lat !== null && lng !== null)
        markersData.push({ lat, lng, markerElement });
    }

    return supercluster(markersData, { radius: 60 });
  }

  getMaps = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    return (
      <>
        <div
          ref={this.mapsLayout}
          onContextMenu={(ev) => {
            ev.preventDefault();
          }}
          style={this.getStyleProp()}
        >
          {this.props.map !== undefined && this.props.maps !== undefined && (
            <div className="input-group">
              <div className="input-group-prepend" style={{ position: "relative" }}>
                <AutoComplete
                  map={this.props.map}
                  maps={this.props.maps}
                  addplace={this.selectPlace}
                  hasSearchBox={this.props.hasSearchBox}
                  onChangeLocation={(location: ICoords) => {
                    this.setState({ latitude: location.latitude, longitude: location.longitude, zoom: 17 });
                  }}
                />
              </div>
            </div>
          )}
          {isDesignTime === true ? <>{this.getMapForDesignTime()}</> : <>{this.getMapForRunTime()}</>}
        </div>
      </>
    );
  };

  getMapForDesignTime = () => {
    return <div className="googleMapsMock">{this.getMapsContent()}</div>;
  };

  getMapForRunTime = () => {
    const drawerWidth = this.calculateGroupPickerDrawerWidth();
    const groupPickerDetails = this.handleRenderGroupPickerDetails();

    return (
      <Layout style={{ position: "relative", overflow: "hidden" }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals={true}
          layerTypes={["TrafficLayer", "TransitLayer"]}
          onGoogleApiLoaded={({ map, maps }) => this.handleGoogleApiLoaded(map, maps)}
          center={this.getCenter()}
          zoom={this.getZoom()}
          bootstrapURLKeys={{
            key: "AIzaSyCNHrogcR_C-7UqEASxR0-NGsNIrh6z7lE",
            libraries: ["places", "geometry"]
          }}
          options={{
            draggableCursor: this.props.isPicker === true ? "crosshair" : "grab",
            disableDoubleClickZoom: this.props.disableDoubleClickZoom,
            fullscreenControl: this.props.fullscreenControl,
            streetViewControl: this.props.streetViewControl,
            scaleControl: this.props.scaleControl,
            zoomControl: this.props.zoomControl,
            panControl: this.props.panControl,
            rotateControl: this.props.rotateControl,
            mapTypeControl: this.props.mapTypeControl,
            mapTypeControlOptions: {
              // 6 is LEFT_BOTTOM position. In case of undefined, default value is used.
              position: google?.maps?.ControlPosition?.LEFT_BOTTOM ?? 6,
              mapTypeIds: ["roadmap", "satellite"]
            }
          }}
          draggable={this.state.draggable}
          onChange={this.handleChangeStatus}
          onClick={this.handleMarkerPickerclick}
        >
          {this.getCurrentLocation()}
          {this.getCircleCenterRadius()}
          {this.getMapsContent()}
        </GoogleMapReact>
        {groupPickerDetails && (
          <Drawer
            placement="right"
            getContainer={false}
            open={this.state.isGroupPickerDetailsOpen}
            rootStyle={{ position: "absolute" }}
            width={drawerWidth}
            onClose={() => this.handleCloseGroupPickerDetails()}
          >
            <div>{groupPickerDetails}</div>
          </Drawer>
        )}
      </Layout>
    );
  };

  getMapsContent = () => {
    const markerElements = this.props.children[1];

    if (!markerElements.filter) return <></>;

    const centerCoords = this.getCenter();
    const radius = this.props.circleRadius;

    const { zoom, latitude, longitude, bounds } = this.state;
    const center = { lat: latitude, lng: longitude };

    if (bounds) {
      const clusters = this.getClusters(markerElements);

      return clusters({ zoom, bounds, center }).map((item) => {
        if (radius && this.calculateDistance(centerCoords, { lat: item.wy, lng: item.wx }) > radius) return <></>;

        if (item.numPoints === 1) {
          return this.renderMapsMarkerChild(item.points[0].markerElement);
        }
        return this.renderGroupPickerChild(item);
      });
    }

    return React.Children.map(this.props.children, (child: any, i) => {
      if (child?.props && child?.props?.record) {
        return this.renderMapsMarkerChild(child);
      }
      return <></>;
    });
  };

  renderMapsMarkerChild(child) {
    let props = _.clone(child.props);
    props.value = this.props.value;
    let isSelectedMarker: boolean = false;
    let record = child?.props?.record;
    let c: ICoords | undefined = this.props.value;

    const lat = this.convertToCoordsFieldsFromLatField(record);
    const lng = this.convertToCoordsFieldsFromLngField(record);

    if (record != undefined && c !== undefined && this.props.value && c.latitude === lat && c.longitude === lng) {
      isSelectedMarker = true;
    }

    if (!Number.isNaN(lat) && !Number.isNaN(lng) && lat !== null && lng !== null) {
      return (
        <div
          style={{ ...this.getMapsItemStyle(isSelectedMarker) }}
          onMouseOver={this.handleMarkerInteractionMouseOver}
          onMouseOut={this.handleMarkerInteractionMouseOut}
          onClick={() => this.handleClickDataMarker({ lat, lng })}
          // @ts-ignore
          alt={child.props?.record?.label}
          marker={{ lat, lng }}
          lat={lat}
          lng={lng}
        >
          {React.cloneElement(child, props, child.props.children)}
        </div>
      );
    }
  }

  getGroupPickerElement = (cluster, groupPickerIcon, groupPickerColor) => {
    if (groupPickerIcon) {
      return (
        <span
          style={{
            display: "inline-flex",
            flexDirection: "column",
            color: groupPickerColor,
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: 12
          }}
        >
          <Icon iconName={this.props.groupPickerIcon} />
          {cluster.numPoints}
        </span>
      );
    }

    const defaultGroupMarkerSVG = (
      <span
        style={{
          position: "relative",
          width: 35,
          height: 47,
          display: "inline-flex",
          transform: "translate(-50%, -50%)"
        }}
      >
        <svg width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.3483 40.5309L8.21335 31.4229C7.38437 30.1922 6.80231 29.3262 6.42773 28.6429C6.0481 27.9503 5.97348 27.6013 5.98554 27.3875C6.01852 26.8023 6.30654 26.261 6.77348 25.9067C6.94413 25.7772 7.27528 25.6441 8.06179 25.5721C8.8378 25.501 9.88119 25.5 11.3651 25.5L23.6349 25.5C25.1188 25.5 26.1622 25.501 26.9382 25.5721C27.7247 25.6441 28.0559 25.7772 28.2265 25.9067C28.6935 26.261 28.9815 26.8023 29.0145 27.3875C29.0265 27.6013 28.9519 27.9503 28.5723 28.6429C28.1977 29.3262 27.6156 30.1922 26.7866 31.4229L20.6517 40.5309C19.9746 41.5362 19.504 42.2332 19.1014 42.7275C18.7005 43.2199 18.4522 43.4007 18.2626 43.4789C17.7742 43.6804 17.2258 43.6804 16.7374 43.4789C16.5478 43.4007 16.2995 43.2199 15.8986 42.7275C15.496 42.2332 15.0254 41.5362 14.3483 40.5309Z"
            stroke="#FF0000"
            stroke-width="2"
          />
          <circle cx="17.5" cy="17" r="16" fill="#FB8684" stroke="#FF0000" stroke-width="2" />
        </svg>
        <span
          style={{
            position: "absolute",
            top: "calc(50% - 17px)",
            width: "100%",
            textAlign: "center",
            color: "#fff",
            fontSize: "17px",
            fontWeight: "bold"
          }}
        >
          {cluster.numPoints}
        </span>
      </span>
    );

    return defaultGroupMarkerSVG;
  };

  renderGroupPickerChild(cluster) {
    const { groupPickerColor, groupPickerIcon } = this.props;

    return (
      <div
        onClick={() => this.handleOpenGoupPickerDetails(cluster.points.map((point) => point.markerElement))}
        style={{
          cursor: "pointer"
        }}
        // @ts-ignore
        lat={cluster.wy}
        lng={cluster.wx}
      >
        {this.getGroupPickerElement(cluster, groupPickerIcon, groupPickerColor)}
      </div>
    );
  }

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;
    return isDesignTime !== true &&
      (this.props.markerDetailView === MarkerDetailView.SidePanel ||
        this.props.markerDetailView === MarkerDetailView.Drawer) ? (
      <>
        <SidePanel
          content={this.props.activeContent}
          markerDetailView={this.props.markerDetailView}
          markerDetailPosition={this.props.markerDetailPosition}
          markerDetailHeight={this.props.markerDetailHeight}
          markerDetailWidth={this.props.markerDetailWidth}
          designClicked={this.props.designClicked}
          onClose={() => {
            if (this.props.clearActiveContent) {
              this.props.clearActiveContent();
            }
          }}
        >
          {this.getMaps()}
        </SidePanel>
      </>
    ) : (
      this.getMaps()
    );
  }

  getMapsItemStyle = (isSelectedMarker: boolean) => {
    const result: any = {};
    result.cursor = "pointer";
    result.borderWidth = 0;
    // result.borderStyle = "solid";
    // result.borderColor = "#55931c";
    result.cursor = "pointer";
    result.position = "absolute";
    result.zIndex = 99;
    result.boxShadow = isSelectedMarker
      ? "-1px 3px 8px 5px rgb(255 255 255 / 41%), 2px 5px 16px 0px rgb(125 157 111 / 21%), 2px 1px 30px 11px rgb(255 255 255 / 42%)"
      : "";
    result.borderRadius = "15px";
    result.borderStyle = "solid";
    if (!window.kuika?.isDesignTime) {
      result.transform = "translate(-50%, -50%)";
    }
    // result.backgroundColor = "white";
    return result;
  };
}

interface SidePanelProps {
  content: any;
  children: any;
  onClose?: () => void;
  markerDetailView?: MarkerDetailView;
  markerDetailPosition?: MarkerDetailPosition;
  markerDetailHeight?: number;
  markerDetailWidth?: number;
  designClicked?: boolean;
}

class SidePanel extends PureComponent<SidePanelProps> {
  constructor(props: SidePanelProps) {
    super(props);
  }

  public static defaultProps = {
    position: MarkerDetailPosition.Bottom
  };

  getstyle = (): CSSProperties => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime !== true && this.props.markerDetailView === MarkerDetailView.SidePanel) {
      return { position: "absolute" };
    }
    return {};
  };

  getPlacement = () => {
    if (this.props.markerDetailPosition === MarkerDetailPosition.Bottom) {
      return "bottom";
    }
    if (this.props.markerDetailPosition === MarkerDetailPosition.Top) {
      return "top";
    }
    if (this.props.markerDetailPosition === MarkerDetailPosition.Left) {
      return "left";
    }
    if (this.props.markerDetailPosition === MarkerDetailPosition.Right) {
      return "right";
    }

    return "bottom";
  };

  render = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    return (
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          textAlign: "center",
          background: "#fafafa",
          border: "1px solid #ebedf0",
          borderRadius: "2px;"
        }}
      >
        <div>{this.props.children}</div>
        <Drawer
          placement={this.getPlacement()}
          contentWrapperStyle={{ padding: 15, bottom: 10 }}
          closable={false}
          onClose={() => {
            if (this.props.onClose) {
              this.props.onClose();
            }
          }}
          height={this.props.markerDetailHeight}
          width={this.props.markerDetailWidth}
          open={
            (this.props.content !== undefined && isDesignTime === false) ||
            (this.props.designClicked === true && isDesignTime === true)
          }
          getContainer={false}
          rootStyle={this.getstyle()}
          mask={
            !(
              this.props.markerDetailView === MarkerDetailView.SidePanel ||
              (this.props.designClicked === true && isDesignTime === true)
            )
          }
        >
          {this.props.content}
        </Drawer>
      </div>
    );
  };
}
