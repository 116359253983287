import { PureComponent, ReactNode } from "react";

interface FlexGridViewWrapperProps {
  style?: any;
}

interface FlexGridViewWrapperState {}

export class FlexGridViewWrapper extends PureComponent<FlexGridViewWrapperProps, FlexGridViewWrapperState> {
  constructor(props: FlexGridViewWrapperProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
