import { Button as AntdButton, Space } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/lib/button/button";
import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import withDoubleClickEdit from "../../../shared/hoc/with-double-click-edit";
import { CommonProps } from "../common-props";
import { Icon } from "../icon/icon";

interface ButtonProps extends AntdButtonProps {
  label?: string;
  icon?: string;
  style?: any;
  iconColor?: string;
  iconPosition?: "left" | "right";
}

interface ButtonState {}

class Button extends PureComponent<ButtonProps & CommonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);
    style.whiteSpace = "normal";
    props.style = style;
    if (props.icon && props.icon.length > 0) {
      delete props.icon;
    }
    if (props.label) {
      delete props.label;
    }
    if (props.iconColor) {
      delete props.iconColor;
    }
    if (props.style.fontFamily) {
      delete props.style.fontFamily;
    }
    if (props.style.fontSize) {
      delete props.style.fontSize;
    }
    if (props.style.fontWeight) {
      delete props.style.fontWeight;
    }
    if (!props.style?.backgroundColor) {
      props.style.backgroundColor = "transparent";
    }
    if (!props.style.borderColor) {
      props.style.borderColor = "transparent";
    }
    props.block = this.props.style?.display === "block";
    if (props.href === "") delete props.href;
    return props;
  };

  getIcon = () => {
    return (
      <Icon
        style={{
          fontSize: this.props.style?.fontSize || "16px",
          position: this.props.label !== undefined ? "relative" : undefined,
          color: this.props.style?.color && this.props.style?.color?.length > 0 ? this.props.style?.color : "white",
          paddingBottom: "2px"
        }}
        iconName={this.props.icon as string}
      />
    );
  };

  getLeftIcon = () => {
    if (this.props.iconPosition !== "right" && this.props.icon && this.props.icon.length > 0) {
      return this.getIcon();
    }
    return <></>;
  };

  getRightIcon = () => {
    if (this.props.iconPosition === "right" && this.props.icon && this.props.icon.length > 0) {
      return this.getIcon();
    }
    return <></>;
  };

  getVerticalAlign = () => {
    const alignItems = this.props.style?.alignItems;
    if (alignItems === "flex-start") return "start";
    if (alignItems === "flex-end") return "end";
    return "center";
  };

  getTextStyles = () => {
    const styles: CSSProperties = {};
    if (this.props.style?.fontFamily) {
      styles.fontFamily = this.props.style?.fontFamily;
    }
    if (this.props.style?.fontSize) {
      styles.fontSize = this.props.style?.fontSize;
    }
    if (this.props.style?.fontWeight) {
      styles.fontWeight = this.props.style?.fontWeight;
    }

    if (this.props.style?.textDecoration) {
      styles.textDecoration = this.props.style?.textDecoration;
    }

    return styles;
  };

  render(): ReactNode {
    const leftIcon = this.getLeftIcon();
    const rightIcon = this.getRightIcon();
    return (
      <>
        <AntdButton {...this.getProps()}>
          <Space style={{ height: "100%", ...this.getTextStyles() }} align={this.getVerticalAlign()}>
            {leftIcon}
            {this.props.label && this.props.label.length && this.props.label}
            {rightIcon}
          </Space>
        </AntdButton>
      </>
    );
  }
}

const button = withDoubleClickEdit(withCommonEvents(Button), "label");
export { button as Button };
