import { Switch } from "antd";
import { useState } from "react";

const TriStateSwitch = ({ value, onChange, language, source }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const toggleState = (e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    const newValue = currentValue === null ? true : currentValue === true ? false : null;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const getLabel = () => {
    if (currentValue === null) return language === "tr_TR" ? "İnaktif" : "Inactive";
    return currentValue ? (language === "tr_TR" ? "Evet" : "Yes") : language === "tr_TR" ? "Hayır" : "No";
  };

  return (
    <div
      onClick={toggleState}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: 8,
        position: source === "TableHeaderCell" ? "absolute" : "relative",
        bottom: source === "TableHeaderCell" ? 0 : undefined
      }}
    >
      <Switch checked={currentValue === true} checkedChildren={getLabel()} unCheckedChildren={getLabel()} />
    </div>
  );
};

export default TriStateSwitch;
