import { PureComponent, ReactNode } from "react";

interface GalleryBodyCellProps {}

interface GalleryBodyCellState {}

export class GalleryBodyCell extends PureComponent<GalleryBodyCellProps, GalleryBodyCellState> {
  constructor(props: GalleryBodyCellProps) {
    super(props);
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
