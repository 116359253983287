import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { CommonProps } from "../../../common/common-props";
import { Row } from "../../../responsive/row";

export interface GalleryBodyWrapperProps {
  style?: any;
  horizontalGutter?: number;
  verticalGutter?: number;
}

interface GalleryBodyWrapperState {}

export class GalleryBodyWrapper extends PureComponent<GalleryBodyWrapperProps & CommonProps, GalleryBodyWrapperState> {
  constructor(props: GalleryBodyWrapperProps & CommonProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    const node = ReactDOM.findDOMNode(this);
    const parentElement = node?.parentElement;
    if (parentElement && this.props?.horizontalGutter) {
      parentElement.style.overflow = "hidden";
    }
  }

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (!props.style) props.style = {};
    if (props.horizontalGutter) {
      const horizontalGutter = Math.abs(Number(props.horizontalGutter));
      const p = props.horizontalGutter.toString().includes("-") ? "" : "-";
      props.style.marginLeft = `${p + horizontalGutter}px`;
      props.style.marginRight = `${p + horizontalGutter}px`;
      delete props.horizontalGutter;
    }

    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Row {...this.getProps()}></Row>
      </>
    );
  }
}
