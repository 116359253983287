import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";

interface TableHeaderProps {
  style?: any;
  writingMode?: "horizontal" | "vertical";
  columnTitles?: string;
  id?: string;
  hideOnMobileResolution?: boolean;
  wrapperClassname?: string;
  transformedOnMobileResolution?: boolean;
}

interface TableHeaderState {
  uniqueKey?: Guid;
}

export class TableHeader extends PureComponent<TableHeaderProps, TableHeaderState> {
  constructor(props: TableHeaderProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount(): void {
    const tableHeader = ReactDOM.findDOMNode(this) as Element;
    const table = tableHeader?.parentElement;
    if (table && this.props.hideOnMobileResolution === true) {
      table.classList.add("ktableheader_without-title");
    }
    if (this.props.transformedOnMobileResolution === true) {
      this.setDynamicStyle();
    }
  }

  getDynamicCss = (): string => {
    const className: string = this.props.wrapperClassname;
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const {
      fontSize,
      fontWeight,
      lineHeight,
      color,
      backgroundColor,
      fontFamily,
      textDecoration,
      textTransform,
      fontStyle,
      letterSpacing
    } = this.props.style;

    if (
      fontSize ||
      fontWeight ||
      lineHeight ||
      color ||
      backgroundColor ||
      fontFamily ||
      textDecoration ||
      textTransform ||
      fontStyle ||
      letterSpacing
    ) {
      result += `${className} td::before {
        ${fontSize ? `font-size: ${fontSize};` : ""}
        ${fontWeight ? `font-weight: ${fontWeight};` : ""}
        ${lineHeight ? `line-height: ${lineHeight};` : ""}
        ${color ? `color: ${color};` : ""}
        ${backgroundColor ? `background-color: ${backgroundColor};` : ""}
        ${fontFamily ? `font-family: ${fontFamily};` : ""}
        ${textDecoration ? `text-decoration: ${textDecoration};` : ""}
        ${textTransform ? `text-transform: ${textTransform};` : ""}
        ${fontStyle ? `font-style: ${fontStyle};` : ""}
        ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ""}
      }`;
    }

    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
    }
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props?.style?.display) {
      delete props.style.display;
    }
    return props;
  };

  render(): ReactNode {
    return <thead {...this.getProps()}></thead>;
  }
}
