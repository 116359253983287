import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";
import { BaseTable, ITableProps as TableProps, ITableState as TableState } from "./base-table";

class Table extends BaseTable<TableProps & CommonProps, TableState> {
  constructor(props: TableProps & CommonProps) {
    super(props);
  }

  public static defaultProps = {
    mode: "table",
    showHeader: true,
    designClicked: false
  };
}

const table = withCommonEvents(Table);
export { table as Table };
