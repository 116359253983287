import { PureComponent, ReactNode } from "react";

interface GoogleMapsBodyCellProps {}

interface GoogleMapsBodyCellState {}

export class GoogleMapsBodyCell extends PureComponent<GoogleMapsBodyCellProps, GoogleMapsBodyCellState> {
  constructor(props: GoogleMapsBodyCellProps) {
    super(props);
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
