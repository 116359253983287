import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import { ViewMode } from "../../base-table";

interface TableBodyCellProps {
  calculatedCellBgColor?: (rowIndex?: number, rowData?: any) => string;
  calculatedCellFontColor?: (rowIndex?: number, rowData?: any) => string;
  style?: any;
  rowData?: any;
  rowIndex?: number;
  columnIndex: number;
  title: string;
  mode?: ViewMode;
  onCellClick: (e) => void;
  onCellBlur: (e) => void;
  isEditable?: boolean;
  isEditMode?: boolean;
  dataValue?: any;
  editableAlwaysActive?: boolean;
  setResizingColumnIndex?: (index: number) => void;
  resizeListener?: (e: MouseEvent) => void;
  index?: number;
  resizableColumns?: boolean;
}
interface TableBodyCellState {}

declare let window: any;

export class TableBodyCell extends PureComponent<TableBodyCellProps, TableBodyCellState> {
  constructor(props: TableBodyCellProps) {
    super(props);
  }

  getStyle = (): React.CSSProperties => {
    let style: React.CSSProperties = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (this.props.calculatedCellBgColor) {
      style.background = this.props.calculatedCellBgColor(this.props.rowIndex, this.props.rowData);
    }
    if (this.props.calculatedCellFontColor) {
      style.color = this.props.calculatedCellFontColor(this.props.rowIndex, this.props.rowData);
    }
    if (
      !style.border &&
      !style.borderColor &&
      !style.borderTop &&
      !style.borderBottom &&
      !style.borderRight &&
      !style.borderLeft &&
      !style.borderWidth
    ) {
      style.borderWidth = 0;
    }
    if (style?.display) {
      delete style.display;
    }
    if (!style?.padding) {
      style.padding = "0px";
    }
    if (style.padding && (style?.paddingLeft || style?.paddingRight || style?.paddingTop || style?.paddingBottom)) {
      delete style.padding;
    }
    return style;
  };

  handleCellClick = (e: any) => {
    if (this.props.onCellClick) {
      this.props.onCellClick(e);
    }
  };

  handleCellBlur = (e: any) => {
    if (this.props.onCellBlur) {
      this.props.onCellBlur(e);
    }
  };

  filterNodeByKuikaComponentName(node) {
    if (!node || !node.props) {
      return true;
    }
    const { props } = node;

    if (props && props.kuikacomponentname != "Label") {
      return node;
    }

    if (props && props.children) {
      const filteredChildren = React.Children.map(props.children, (child) =>
        this.filterNodeByKuikaComponentName(child)
      );

      const hasFilteredChildren = filteredChildren.some((child) => child !== null);
      if (hasFilteredChildren) {
        return {
          ...node,
          props: {
            ...props,
            children: filteredChildren
          }
        };
      }
    }

    return null;
  }

  componentDidMount(): void {
    if (
      this.props.editableAlwaysActive &&
      window?.editingColumnData &&
      window.editingColumnData.columnIndex !== undefined &&
      window.editingColumnData.columnIndex !== this.props.columnIndex &&
      window.editingColumnData.rowIndex?.toString() === this.props.rowIndex?.toString()
      // &&
      // window.editingColumnData.isKeyDownTriggered
    ) {
      const nextElement = this.getNextElement();
      if (nextElement) {
        let inputElement = nextElement.querySelector("input");
        if (inputElement && document.activeElement !== inputElement) {
          inputElement.focus();
          setTimeout(() => {
            inputElement.focus();
          }, 0);
        }
      }
    }
  }

  getNextElement = () => {
    let activeElement = window.document.querySelector(
      `[columnindex="${window?.editingColumnData?.columnIndex}"][rowindex="${window?.editingColumnData?.rowIndex}"]`
    );
    return activeElement;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    props.tableTitle = this.props.title;
    props.dataValue = this.props.dataValue;
    props.title = "";
    const screenWidth = window.innerWidth;
    if (this.props.resizableColumns && !window.kuika.isDesignTime && screenWidth > 1024) {
      delete props.children;
    }
    return props;
  };

  render(): ReactNode {
    const screenWidth = window.innerWidth;
    if (this.props.resizableColumns && !window.kuika.isDesignTime && screenWidth > 1024) {
      return (
        <td
          onClickCapture={this.handleCellClick}
          {...this.getProps()}
          style={this.getStyle()}
          onBlur={this.handleCellBlur}
        >
          <div
            className="resizeHandle"
            onMouseDown={() => {
              this.props.setResizingColumnIndex(this.props.index);
              window.addEventListener("mousemove", this.props.resizeListener, { passive: true });
            }}
          />
          {this.props.children}
        </td>
      );
    }
    return (
      <td
        onClickCapture={this.handleCellClick}
        {...this.getProps()}
        style={this.getStyle()}
        onBlur={this.handleCellBlur}
      />
    );
  }
}
