import { Input } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;

interface PasswordProps {
  value?: string;
  placeholder: string;
  style: any;
}

interface PasswordState {
  uniqueKey: Guid;
}

class Password extends PureComponent<PasswordProps & CommonProps, PasswordState> {
  constructor(props: PasswordProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    if (this.props.style?.backgroundColor || this.props.style?.color) {
      const dynamic_style = document.getElementById("dynamic_style");
      if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
        dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${this.getDynamicCss()}`;
      }
      this.handleAffixStyling();
    }
  };

  handleAffixStyling = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const input = node.getElementsByTagName("input")[0];
    if (!input) {
      return;
    }
    if (this.props?.style?.backgroundColor) {
      input.style.backgroundColor = this.props.style.backgroundColor as any;
    }
    if (this.props?.style?.color) {
      input.style.color = this.props.style.color as any;
    }
  };

  getBgColor = (): string | undefined => {
    if (this.props.style?.backgroundColor) {
      return `background-color: ${this.props.style?.backgroundColor} !important;`;
    }
  };

  getColor = (): string | undefined => {
    if (this.props.style?.color) {
      return `color: ${this.props.style?.color} !important;`;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    const bgColor = this.getBgColor();
    const color = this.getColor();
    const { lineHeight, fontSize, fontWeight, letterSpacing } = this.props.style;
    const colorStyle = this.props.style?.color;
    let result = "";
    if (bgColor || color || lineHeight || fontSize || fontWeight || letterSpacing) {
      result += `.${className.trim()} > .ant-input {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;
      result += `.${className.trim()} .ant-input:placeholder-shown, input::placeholder {
          font-size: ${fontSize} !important;
          color: ${colorStyle} !important;
          font-weight: ${fontWeight} !important;
          letter-spacing: ${letterSpacing} !important;
          line-height: ${lineHeight} !important;
        }`;
      result += `
      .${className.trim()} > span > span {
          ${bgColor !== undefined ? `${bgColor}` : ``}${color !== undefined ? color : ``}
        }`;
    }
    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kpassword_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);

    if (style.display === "block") {
      style.display = "flex";
    } else if (style.display === "inline") {
      style.display = "inline-flex";
    }

    if (props.value && style.textTransform === "lowercase") {
      props.value = props.value.toString().toLowerCase();
    } else if (props.value && style.textTransform === "uppercase") {
      props.value = props.value.toString().toUpperCase();
    }

    props.style = style;
    props.className = "kuika-email";
    return props;
  };

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime) {
      return (
        <>
          <Input
            className={this.getClassName()}
            {...this.getProps()}
            suffix={
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.83301 13.3337L14.1667 1"
                  stroke="#818C99"
                  strokeWidth="1.4"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M7.99667 2.16675C8.8567 2.16675 9.69672 2.30009 10.4934 2.55343L9.67339 3.37345C9.13004 3.23678 8.56669 3.16678 7.99667 3.16678C4.77657 3.16678 1.75982 5.44018 0.983125 8.45694C0.916456 8.72695 0.643115 8.88695 0.37644 8.81695C0.106432 8.75028 -0.0535731 8.47694 0.016429 8.21026C0.916456 4.70682 4.27322 2.16675 7.99667 2.16675Z"
                  fill="#818C99"
                />
                <path
                  d="M15.6234 8.81694C15.5834 8.83027 15.54 8.8336 15.5 8.8336C15.2767 8.8336 15.0733 8.6836 15.0167 8.45693C14.5133 6.5002 13.0633 4.85348 11.2266 3.94012L11.9799 3.18677C13.9266 4.24013 15.4267 6.04019 15.9834 8.21025C16.0534 8.47693 15.8934 8.75027 15.6234 8.81694Z"
                  fill="#818C99"
                />
                <path
                  d="M9.79043 5.37685L9.06374 6.10353C9.75376 6.48021 10.2238 7.21357 10.2238 8.05693C10.2238 9.2803 9.22707 10.277 8.00037 10.277C7.16035 10.277 6.42699 9.80698 6.05031 9.11696L5.32362 9.84365C5.90364 10.707 6.887 11.277 8.00037 11.277C9.77709 11.277 11.2238 9.83031 11.2238 8.05693C11.2238 6.94356 10.6538 5.95686 9.79043 5.37685ZM6.94367 6.10353L8.20704 4.84016C8.13704 4.83683 8.07037 4.8335 8.00037 4.8335C6.22365 4.8335 4.78027 6.28021 4.78027 8.05693C4.78027 8.1236 4.78361 8.1936 4.78694 8.26027L6.05031 6.99689C6.25365 6.62022 6.56699 6.30687 6.94367 6.10353Z"
                  fill="#818C99"
                />
              </svg>
            }
          />
        </>
      );
    }
    return (
      <>
        <Input.Password className={this.getClassName()} {...this.getProps()} autoComplete="new-password" />
      </>
    );
  }
}

const password = withCommonEvents(Password);
export { password as Password };
