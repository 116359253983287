import { Guid } from "guid-typescript";
import React, { ImgHTMLAttributes, PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { DEFAULT_IMAGE } from "../../../shared/utilty/constants";
import { KuikaAppManager } from "../../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

const backendUrl = KuikaAppManager.GetBackendUrl();

declare let window: Window & { kuika: any };

interface ImageProps extends ImgHTMLAttributes<any> {
  value?: string;
  style?: React.CSSProperties;
  placeHolderImage?: string;
  onChange?: (value: any) => void;
  zoomOnClick?: boolean;
  position?: "bottom" | "center" | "right" | "left" | "top";
  imageFit?: "fill" | "fit" | "stretch";
}

interface ImageState {
  guidID: string;
  isZoomed: boolean;
  isLoading: boolean;
}

class Image extends PureComponent<ImageProps & CommonProps, ImageState> {
  private errorCountPerUrlMap: Map<string, number> = new Map<string, number>();

  constructor(props: ImageProps) {
    super(props);
    this.state = {
      guidID: "",
      isZoomed: false,
      isLoading: false
    };
  }

  componentDidMount() {
    const node = findDOMNode(this) as HTMLDivElement;
    const isReportDesigner = window?.kuika?.dashboardState === DashboardState.reportDesigner;
    if (isReportDesigner && node && node.parentElement) {
      if (!node?.parentElement?.classList?.contains("kuika_absoluteDesignTableColumnChild")) {
        node.style.width = "100%";
        node.style.height = "100%";
      } else {
        node.style.width = `${this.props.style?.width}` ?? "100%";
        node.style.height = `${this.props.style?.height}` ?? "100%";
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<ImageProps & CommonProps>) {
    if (prevProps.value !== this.props.value) {
      this.errorCountPerUrlMap.clear();
    }
  }

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  getStyleProp = () => {
    let style: React.CSSProperties = {};
    const isReportDesigner = window?.kuika?.dashboardState === DashboardState.reportDesigner;
    const isDesignTime = window?.kuika?.isDesignTime;

    if (this.props.style) {
      style = { ...this.props.style };
    }

    if (this.props.showCursorPointer) {
      style.cursor = "pointer";
    }

    if (this.props.position) {
      style.objectPosition = this.props.position;
    }

    if (this.props.imageFit) {
      if (this.props.imageFit === "fill") {
        style.objectFit = "cover";
      } else if (this.props.imageFit === "fit") {
        style.objectFit = "contain";
      }
    }

    if (style.display === "inline") {
      style.display = "inline-block";
    }

    if (isReportDesigner) {
      style.objectFit = "contain";
    }

    return style;
  };

  getClassName = () => {
    const isDesignTime = window?.kuika?.isDesignTime;
    let result = "";

    if (this.props.className) {
      result = this.props.className;
    }

    if (!isDesignTime) result += " kuika-image-input ";
    return result;
  };

  private getImageFitValue = () => {
    switch (this.props.imageFit) {
      case "fill":
        return 1;
      case "stretch":
        return 2;
      case "fit":
      default:
        return 0;
    }
  };

  private getImageWidth = (): number => {
    return parseInt(this.props.style.width?.toString()?.replace("px", "") ?? "0", 10);
  };

  private getImageHeight = (): number => {
    return parseInt(this.props.style.height?.toString()?.replace("px", "") ?? "0", 10);
  };

  getValue = () => {
    const props = { ...this.props };
    let currentValue: string = DEFAULT_IMAGE;

    if (props.value && props.value !== "") {
      if (
        (typeof props.value === "string" && props.value.startsWith("http://")) ||
        props.value.startsWith("https://")
      ) {
        currentValue = props.value;
        return currentValue;
      }
      if (
        typeof props.value === "string" &&
        (props.value.includes("/image") || props.value.includes("/resource/runtime/byid"))
      ) {
        const url: URL = new URL(backendUrl + props.value);
        url.searchParams.append("width", this.getImageWidth().toString());
        url.searchParams.append("height", this.getImageHeight().toString());
        url.searchParams.append("imageFit", this.getImageFitValue().toString());
        url.searchParams.append("t", Date.now().toString());
        currentValue = url.toString();
      } else {
        currentValue = props.value;
      }
    } else if (props.placeHolderImage) {
      currentValue = props.placeHolderImage;
    }

    const guidValue = Guid.parse(currentValue).toString();

    if (guidValue !== Guid.EMPTY) {
      currentValue = KMainFunctions.getUrlByWithResourceId(
        guidValue,
        props?.style?.width?.toString()?.replace("px", "") ?? 0,
        props?.style?.height?.toString()?.replace("px", "") ?? 0,
        this.getImageFitValue()
      );
    }

    return currentValue;
  };

  isPlaceholderImageApplicable = () => {
    return this.props.placeHolderImage && this.props.placeHolderImage !== "";
  };

  onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!(e.target instanceof HTMLImageElement)) return;

    const currentErrorCountOfThisUrl: number = this.errorCountPerUrlMap.get(e.currentTarget.src) ?? 0;

    // If higher than 1, it means it has already been tried to fallback to placeholder image & failed.
    if (currentErrorCountOfThisUrl >= 1) {
      e.currentTarget.onerror = null;
      e.currentTarget.src = DEFAULT_IMAGE;
      return;
    }

    this.errorCountPerUrlMap.set(e.currentTarget.src, currentErrorCountOfThisUrl + 1);

    let imageToFallback: string = DEFAULT_IMAGE;
    if (this.isPlaceholderImageApplicable()) {
      const placeholderGuid: string = Guid.parse(this.props.placeHolderImage).toString();

      if (placeholderGuid !== Guid.EMPTY) {
        imageToFallback = KMainFunctions.getUrlByWithResourceId(
          placeholderGuid,
          this.props.style.width?.toString()?.replace("px", "") ?? 0,
          this.props.style.height?.toString()?.replace("px", "") ?? 0,
          this.getImageFitValue()
        );
      }

      e.target.src = imageToFallback;
      return;
    }

    e.target.src = imageToFallback;
  };

  render(): ReactNode {
    if (this.props.zoomOnClick) {
      return (
        <ControlledZoom
          isZoomed={this.state.isZoomed}
          onZoomChange={(shouldZoom) => this.setState({ isZoomed: shouldZoom })}
        >
          <img
            id={this.props.id}
            draggable={!window?.kuika?.isDesignTime}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onClickCapture={() => this.setState({ isZoomed: !this.state.isZoomed })}
            className={this.getClassName()}
            onChange={this.onChange}
            src={this.getValue()}
            style={this.getStyleProp()}
            onClick={this.props.onClick}
            onError={this.onError}
          />
        </ControlledZoom>
      );
    }

    return (
      <img
        id={this.props.id}
        draggable={!window?.kuika?.isDesignTime}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        className={this.getClassName()}
        onChange={this.onChange}
        src={this.getValue()}
        style={this.getStyleProp()}
        onClick={this.props.onClick}
        onError={this.onError}
      />
    );
  }
}

const image = withCommonEvents(Image);
export { image as Image };
