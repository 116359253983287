import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import { ValueType } from "../base-table";
import TriStateSwitch from "../tri-state-switch";

interface TableHeaderCellProps {
  style?: any;
  columnStyle?: any;
  textDirection?: string;
  columnSearchText?: string;
  setColumnSearchText?: (key, value) => void;
  title?: string;
  children?: any;
  searchable?: boolean;
  type?: ValueType;
  dataValue?: string;
  resizeListener?: (e: MouseEvent) => void;
  setResizingColumnIndex?: (index: number) => void;
  resizableColumns?: boolean;
  isSearchHidden?: boolean;
  className?: string;
  isFilteringHidden?: boolean;
  isSortingHidden?: boolean;
  index?: number;
  onHeaderSearchBlur?: () => void;
}

interface TableHeaderCellState {
  searchValue: string;
}

declare let window: any;

export class TableHeaderCell extends PureComponent<TableHeaderCellProps, TableHeaderCellState> {
  debouncedSetSearchText: (value: string) => void;

  constructor(props: TableHeaderCellProps) {
    super(props);
    this.state = {
      searchValue: ""
    };
    this.debouncedSetSearchText = _.debounce(this.setSearchText.bind(this), 200);
  }

  getStyle = (): any => {
    let style: any = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }
    if (
      !style.border &&
      !style.borderColor &&
      !style.borderTop &&
      !style.borderBottom &&
      !style.borderRight &&
      !style.borderLeft &&
      !style.borderWidth
    ) {
      style.borderWidth = 0;
    }
    if (!style.backgroundColor && !style.background) {
      style.backgroundColor = "transparent";
    }
    if (this.props.columnStyle && this.props.columnStyle.textAlign) {
      style.textAlign = this.props.columnStyle.textAlign;
    }
    if (this.props.textDirection && this.props.textDirection !== "Default") {
      if (style.textAlign === "-webkit-left") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "bottom" : "top";
      } else if (style.textAlign === "-webkit-right") {
        style.verticalAlign = this.props.textDirection === "Rotate Up" ? "top" : "bottom";
      } else {
        style.verticalAlign = "middle";
      }
      style.textAlign = "-webkit-center";
    }
    if (this.props.columnStyle && this.props.columnStyle.padding) {
      style.paddingLeft = this.props.columnStyle.padding;
      style.paddingRight = this.props.columnStyle.padding;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingLeft) {
      style.paddingLeft = this.props.columnStyle.paddingLeft;
    }
    if (this.props.columnStyle && this.props.columnStyle.paddingRight) {
      style.paddingRight = this.props.columnStyle.paddingRight;
    }
    if (style?.display) {
      delete style.display;
    }
    return style;
  };

  componentDidMount(): void {
    const { columnSearchText, dataValue, type } = this.props;
    if (columnSearchText && columnSearchText[dataValue] && dataValue) {
      this.setState({ searchValue: columnSearchText[dataValue] });
      if (window.kuika.columnSearchChanged === dataValue) {
        const tableHeader = ReactDOM.findDOMNode(this) as Element;
        const input: any = tableHeader.querySelector("input");
        if (input) {
          setTimeout(() => {
            input.focus();
            window.kuika.columnSearchChanged = null;
          }, 0);
        }
      }
    } else if (
      dataValue &&
      columnSearchText &&
      type === ValueType.Boolean &&
      (columnSearchText[dataValue] === null ||
        columnSearchText[dataValue] === false ||
        columnSearchText[dataValue] === true ||
        columnSearchText[dataValue] === "false" ||
        columnSearchText[dataValue] === "true")
    ) {
      this.setState({ searchValue: columnSearchText[dataValue] });
    }

    const tableHeader = ReactDOM.findDOMNode(this) as Element;
    const { style } = this.props;
    if (!tableHeader) return;
    const antTableFilterTrigger = tableHeader.querySelector(".ant-table-filter-trigger");
    const antTableColumnSorter = tableHeader.querySelector(".ant-table-column-sorter");
    if (style.color) {
      if (antTableFilterTrigger) {
        antTableFilterTrigger.setAttribute("style", `color: ${style.color}`);
      }
      if (antTableColumnSorter) {
        antTableColumnSorter.setAttribute("style", `color: ${style.color}`);
      }
    }
  }

  setSearchText(value: string) {
    const { setColumnSearchText, dataValue } = this.props;
    if (setColumnSearchText && dataValue) {
      setColumnSearchText(dataValue, value);
    }
    window.kuika.columnSearchChanged = dataValue;
  }

  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ searchValue: value }, () => {
      if (value?.length > 2 || value === "" || value === null || value === undefined) {
        this.debouncedSetSearchText(value);
      }
    });
  };

  handleSearchBlur = () => {
    const { setColumnSearchText, dataValue } = this.props;
    if (setColumnSearchText && dataValue) {
      setColumnSearchText(dataValue, this.state.searchValue);
    }
    if (this.props.onHeaderSearchBlur) this.props.onHeaderSearchBlur();
  };

  renderInput = () => {
    const { type, columnSearchText, dataValue } = this.props;
    const language = localStorage.getItem("ml");
    const { searchValue } = this.state;

    const isDesignTime = window.kuika.isDesignTime;

    const inputType = type === ValueType.Number ? "number" : type === ValueType.Date ? "date" : "text";
    if (type === ValueType.Boolean) {
      return (
        <TriStateSwitch
          value={columnSearchText[dataValue] === true ? true : columnSearchText[dataValue] === false ? false : null}
          onChange={(value) => {
            this.setState({ searchValue: value }, () => {
              this.handleSearchBlur();
              if (this.props.onHeaderSearchBlur) this.props.onHeaderSearchBlur();
            });
          }}
          language={language}
          source={"TableHeaderCell"}
        />
      );
    }
    return (
      <Input
        type={inputType}
        placeholder={language === "tr_TR" ? "Ara..." : "Search..."}
        value={searchValue}
        onClick={(e) => e.stopPropagation()}
        onChange={this.handleSearchChange}
        onBlur={this.handleSearchBlur}
        onPressEnter={(e) => {
          e.stopPropagation();
          this.handleSearchBlur();
        }}
        suffix={
          <SearchOutlined
            onClick={(e) => {
              e.stopPropagation();
              this.handleSearchBlur();
            }}
          />
        }
        readOnly={isDesignTime}
      />
    );
  };

  getProps = (): any => {
    const props = _.clone(this.props);
    let className = props.className;
    if (props.isSearchHidden) {
      className = `${className} hidden-search`;
    }
    if (props.isSortingHidden && !props.isFilteringHidden && !props.isSearchHidden) {
      className = `${className} hidden-sorting`;
    }
    if (props.isFilteringHidden && !props.isSortingHidden && !props.isSearchHidden) {
      className = `${className} hidden-filtering`;
    }
    return {
      ...props,
      className
    };
  };

  render(): ReactNode {
    const hasSearchable = this.props.dataValue || typeof this.props.children[1] === "object";
    const screenWidth = window.innerWidth;
    return (
      <th {...this.getProps()} style={this.getStyle()}>
        {this.props.resizableColumns && !window.kuika.isDesignTime && screenWidth > 1024 && (
          <div
            className="resizeHandle"
            onMouseDown={() => {
              this.props.setResizingColumnIndex(this.props.index);
              window.addEventListener("mousemove", this.props.resizeListener, { passive: true });
            }}
          />
        )}
        {...this.props.children}
        {this.props.title &&
          this.props.title !== "" &&
          this.props.searchable &&
          hasSearchable &&
          !this.props.isSearchHidden &&
          this.renderInput()}
      </th>
    );
  }
}
