import { FooterOptions } from "../../base-table";
import { TableData, TableDataProps } from "../../data/table-data";

export interface TableColumnProps extends TableDataProps {
  style?: any;
  calculatedCellBgColor?: (rowIndex?: number, rowData?: any) => string;
  calculatedCellFontColor?: (rowIndex?: number, rowData?: any) => string;
  onlyRowHoverVisible?: boolean;
  specialSortingFormat?: string;
  isEditableColumn?: boolean;
  isEditMode?: boolean;
  width?: number;
  hideFiltering?: boolean;
  hideSorting?: boolean;
  hideSearch?: boolean;
  defaultDataField?: string;
  footerOptions?: FooterOptions;
  footerFormatter?: string;
}

interface TableColumnState {}

export class TableColumn extends TableData<TableColumnProps, TableColumnState> {
  constructor(props: TableColumnProps) {
    super(props);
    this.state = {};
  }
}
