import { PureComponent, ReactNode } from "react";

interface GoogleMapsViewWrapperProps {
  style?: any;
}

interface GoogleMapsViewWrapperState {}

export class GoogleMapsViewWrapper extends PureComponent<GoogleMapsViewWrapperProps, GoogleMapsViewWrapperState> {
  constructor(props: GoogleMapsViewWrapperProps) {
    super(props);
    this.state = {};
  }

  render(): ReactNode {
    return <div {...this.props} style={{ ...this.props.style, height: undefined }} />;
  }
}
