import { PureComponent, ReactNode } from "react";

interface CarouselBodyCellProps {}

interface CarouselBodyCellState {}

export class CarouselBodyCell extends PureComponent<CarouselBodyCellProps, CarouselBodyCellState> {
  constructor(props: CarouselBodyCellProps) {
    super(props);
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
