import _ from "lodash";
import React, { PureComponent } from "react";

interface RefreshDelegateProps {
  Component: React.ElementType;
  setRefreshDelegate: (refreshMethod: (nextProps?: any, callBack?: any) => void) => void;
}

export class RefreshDelegate extends PureComponent<RefreshDelegateProps, any> {
  constructor(props: any) {
    super(props);
  }

  refresh = (nextProps?: any, callBack?: any) => {
    this.setState(nextProps, () => {
      if (callBack) callBack();
    });
  };

  componentDidMount = () => {
    if (this.props.setRefreshDelegate) {
      this.props.setRefreshDelegate(this.refresh);
    }
  };

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.Component) {
      delete props.Component;
    }
    return props;
  };

  render = () => {
    const { Component } = this.props;
    return <Component {...this.getProps()} {...this.state}></Component>;
  };
}
