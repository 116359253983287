import _ from "lodash";
import { KComponentFunctions } from "../../../../../shared/utilty/component-functions";
import { Col } from "../../../responsive/col";
import { TableRow, TableRowProps, TableRowState } from "../table/table-row";

export interface GalleryItemProps extends TableRowProps {
  desktopColumnsCount?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  tabletColumnsCount?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
  phoneColumnsCount?: 1 | 2 | 3 | 4 | 6 | 8 | 12 | 24;
}

type GalleryItemState = TableRowState;

export class GalleryItem extends TableRow<GalleryItemProps, GalleryItemState> {
  constructor(props: GalleryItemProps) {
    super(props);
    this.state = { mouseOver: false };
  }

  calcSpan = () => {
    const result: any = {};
    if (this.props.desktopColumnsCount) {
      result.xl = 24 / this.props.desktopColumnsCount;
      result.lg = 24 / this.props.desktopColumnsCount;
    }
    if (this.props.tabletColumnsCount) {
      result.lg = 24 / this.props.tabletColumnsCount;
      result.md = 24 / this.props.tabletColumnsCount;
    }
    if (this.props.phoneColumnsCount) {
      result.xs = 24 / this.props.phoneColumnsCount;
    }
    return result;
  };

  handleRowClick = (e: any) => {
    if (
      this.props.rowComponent &&
      this.props.rowComponent.props.onClick &&
      this.props.rowIndex !== undefined &&
      this.props.rowData
    ) {
      if (KComponentFunctions.isButtonElement(e.target) === true) {
        return;
      }
      if (KComponentFunctions.isImgElement(e.target) === true) {
        return;
      }
      this.props.rowComponent.props.onClick(e, this.props.rowIndex, this.props.rowData);
    }
  };

  getStyleMapping = () => {
    const _parentStyleObject = _.clone(this.getStyle());
    const forbiddenStyleKeyList = ["border", "background", "height", "width", "boxShadow"];
    for (const type in _parentStyleObject) {
      forbiddenStyleKeyList.forEach((styleKey) => {
        if (type.includes(styleKey)) {
          delete _parentStyleObject[type];
        }
      });
    }
    return _parentStyleObject;
  };

  render() {
    return (
      <>
        <Col
          {...this.calcSpan()}
          onClick={this.handleRowClick}
          {...this.props}
          style={this.getStyleMapping()}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        />
      </>
    );
  }
}
