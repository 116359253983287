import Tabs from "rc-tabs";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common/common-props";

declare let window: any;
interface TabItemProps {
  style?: any;
  onActive?: () => void;
  forceRender?: boolean;
}

interface TabItemState {}

class TabItem extends PureComponent<TabItemProps & CommonProps, TabItemState> {
  constructor(props: TabItemProps) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.setAlignments();
  }

  componentDidUpdate(
    prevProps: Readonly<TabItemProps & CommonProps>,
    prevState: Readonly<TabItemState>,
    snapshot?: any
  ): void {
    this.setAlignments();
  }

  setAlignments = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    let element = node;
    KMainFunctions.handleAlignments(element, this.props.style);
  };

  getProps = () => {
    if (!window.kuika.isDesignTime) {
      return { ...this.props, forceRender: true };
    }
    return this.props;
  };

  render(): ReactNode {
    return <Tabs.TabPane {...this.getProps()}> {this.props.children} </Tabs.TabPane>;
  }
}

const tabItem = withCommonEvents(TabItem);
export { tabItem as TabItem };
