import { Pagination as AntdPagination } from "antd";
import { PaginationProps as AntdPaginationProps } from "antd/lib/pagination";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";
import { Icon } from "../icon/icon";

declare let window: any;

interface PaginationProps extends AntdPaginationProps, CommonProps {
  style?: any;
  selectedBorderColor?: string;
  total?: number;
  showFirstPageButton?: boolean;
  showLastPageButton?: boolean;
  showNumbers?: boolean;
  showText?: boolean;
  value?: number;
  options?: any;
  datavaluefield?: string;
}

interface PaginationState {
  pageSize: number;
  current: number;
}

class Pagination extends PureComponent<PaginationProps, PaginationState> {
  private static readonly DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

  constructor(props: PaginationProps) {
    super(props);
    this.state = {
      pageSize: this.props.pageSize && this.props.pageSize > 1 ? this.props.pageSize : 10,
      current: this.props.current || 1
    };
  }

  getPageSize = () => {
    const { pageSize } = this.state;
    return pageSize ?? 10;
  };

  getCurrentPage = () => {
    let current: number = this.props.current && this.props.current > 1 ? this.props.current : this.state.current;
    if (!current || current === 0) current = 1;
    return current;
  };

  public getCurrentData = () => {
    const pageSize = this.getPageSize();
    const current = this.getCurrentPage();
    return { pageSize, current };
  };

  public setPaginationData = (newPage: number) => {
    this.handleChange(newPage);
  };

  public setPageSize = (pageSize: number) => {
    this.setState({ pageSize }, () => {
      this.handleChange(this.state.current, pageSize);
    });
  };

  handleChange = (page: number, pageSize: number = this.getPageSize()) => {
    this.setState({ pageSize, current: page }, () => {
      this.props.onChange?.(page, pageSize);
    });
  };

  handleSizeChange = (current: number, pageSize: number) => {
    this.setState({ pageSize, current }, () => {
      this.props.onChange?.(current, pageSize);
    });
  };

  getPageItemStyles = (): any => {
    let result: any = {};
    if (this.props.style) {
      result = _.clone(this.props.style);
    }
    if (this.props.style?.color) {
      result.color = this.props.style?.color;
    }
    if (this.props.style?.borderColor) {
      result.borderColor = undefined;
    }
    if (this.props.style?.borderStyle) {
      result.borderStyle = undefined;
    }
    if (this.props.style?.borderWidth) {
      result.borderWidth = undefined;
    }
    result.textAlign = "center";
    result.alignItems = "center";
    return result;
  };

  getButtonItemStyles = (): any => {
    let result: any = {};
    if (this.props.style) {
      result = _.clone(this.props.style);
    }
    if (this.props.style?.color) {
      result.color = this.props.style?.color;
    }
    if (this.props.style?.borderColor) {
      result.borderColor = undefined;
    }
    if (this.props.style?.borderStyle) {
      result.borderStyle = undefined;
    }
    if (this.props.style?.borderWidth) {
      result.borderWidth = undefined;
    }
    if (this.props.style?.backgroundColor) {
      result.backgroundColor = this.props.style?.backgroundColor;
    }
    if (this.props.style?.backgroundImage) {
      result.backgroundImage = this.props.style?.backgroundImage;
    }
    if (this.props.style?.backgroundRepeat) {
      result.backgroundRepeat = this.props.style?.backgroundRepeat;
    }
    result.textAlign = "center";
    result.alignItems = "center";
    return result;
  };

  getFirst = (): any => {
    return this.props.showText !== true ? (
      <Icon iconName="first_page" style={{ marginBottom: 1 }} />
    ) : (
      this.getPaginationTitle("First")
    );
  };

  getJumpPrev = (): string => {
    return this.props.showText !== true ? "<<" : "<<";
  };

  getPrevious = (): any => {
    return this.props.showText !== true ? <Icon iconName="chevron_left" /> : this.getPaginationTitle("Previous");
  };

  getNext = (): any => {
    return this.props.showText !== true ? <Icon iconName="chevron_right" /> : this.getPaginationTitle("Next");
  };

  getJumpNext = (): string => {
    return this.props.showText !== true ? ">>" : ">>";
  };

  getLast = (): any => {
    return this.props.showText !== true ? (
      <Icon iconName="last_page" style={{ marginBottom: 1 }} />
    ) : (
      this.getPaginationTitle("Last")
    );
  };

  itemRender = (
    page: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    originalElement: React.ReactElement<HTMLElement>
  ) => {
    if (type === "page") {
      return <a style={this.getPageItemStyles()}>{page}</a>;
    }
    if (type === "prev") {
      return <a style={this.getButtonItemStyles()}>{this.getPrevious()}</a>;
    }
    if (type === "next") {
      return <a style={this.getButtonItemStyles()}>{this.getNext()}</a>;
    }
    if (type === "jump-prev") {
      return <a style={this.getButtonItemStyles()}>{this.getJumpPrev()}</a>;
    }
    if (type === "jump-next") {
      return <a style={this.getButtonItemStyles()}>{this.getJumpNext()}</a>;
    }
    return originalElement;
  };

  setStyle = (props?: PaginationProps) => {
    const node: Element | null | Text = ReactDOM.findDOMNode(this);
    if (!node) {
      return;
    }
    const pageBoxes: any = (node as Element)?.querySelectorAll("ul > .ant-pagination-item");

    if (pageBoxes && pageBoxes.length && pageBoxes.length > 0) {
      pageBoxes.forEach((pageBox) => {
        if (props && props.style && props.style.borderColor) {
          pageBox.style["border-color"] = props.style.borderColor;
        }
        if (props && props.style && props.style.borderBottomWidth) {
          pageBox.style["border-bottom-width"] = `${props.style.borderBottomWidth}px`;
        }
        if (props && props.style && props.style.borderTopWidth) {
          pageBox.style["border-top-width"] = `${props.style.borderTopWidth}px`;
        }
        if (props && props.style && props.style.borderLeftWidth) {
          pageBox.style["border-left-width"] = `${props.style.borderLeftWidth}px`;
        }
        if (props && props.style && props.style.borderRightWidth) {
          pageBox.style["border-right-width"] = `${props.style.borderRightWidth}px`;
        }
        if (
          props &&
          props.style &&
          (props.style.borderBottomWidth ||
            props.style.borderTopWidth ||
            props.style.borderLeftWidth ||
            props.style.borderRightWidth)
        ) {
          pageBox.style.display = "flex";
          pageBox.style["justify-content"] = "center";
          pageBox.style["align-items"] = "center";
        }
        if (props && props.style && props.style.borderStyle) {
          pageBox.style["border-style"] = props.style.borderStyle;
        }
        if (
          (props && props.style && props.style.borderTopLeftRadius) ||
          props.style.borderBottomLeftRadius ||
          props.style.borderTopRightRadius ||
          props.style.borderBottomRightRadius
        ) {
          pageBox.style["border-radius"] = `${props.style.borderTopLeftRadius ?? 0}px ${
            props.style.borderTopRightRadius ?? 0
          }px ${props.style.borderBottomRightRadius ?? 0}px ${props.style.borderBottomLeftRadius ?? 0}px`;
        }
        if (props && props.style && props.style.color) {
          pageBox.style.color = props.style.color;
        }
      });
    }
    let paginationWrapper: any = (node as Element)?.querySelectorAll(".ant-pagination");

    if (paginationWrapper && paginationWrapper.length && paginationWrapper.length > 0) {
      paginationWrapper.forEach((pageBox) => {
        if (props && props.style && props.style.borderColor) {
          pageBox.style["border-color"] = "transparent";
        }
        if (props && props.style && props.style.borderWidth) {
          pageBox.style["border-width"] = "0px";
        }
        if (props && props.style && props.style.borderStyle) {
          pageBox.style["border-style"] = "none";
        }
        if (props && props.style && props.style.borderRadius) {
          pageBox.style["border-radius"] = "0px";
        }
      });
    }
    const totalText: any = (node as Element)?.querySelectorAll("ul > .ant-pagination-total-text");
    if (totalText && totalText.length && totalText.length > 0) {
      if (props && props.style && props.style.color) {
        totalText[0].style.color = props.style.color;
      }
    }

    const pageSizeBox: any = (node as Element)?.querySelectorAll("ul > li > div > .ant-select-selector");
    if (pageSizeBox && pageSizeBox.length && pageSizeBox.length > 0) {
      if (props && props.style && props.style.borderColor) {
        pageSizeBox[0].style["border-color"] = props.style.borderColor;
      }
      if (props && props.style && props.style.color) {
        pageSizeBox[0].style.color = props.style.color;
      }
      if (props && props.style && props.style.fontSize) {
        pageSizeBox[0].style.fontSize = props.style.fontSize;
      }
      if (props && props.style && props.style.borderWidth) {
        pageSizeBox[0].style["border-width"] = `${props.style.borderWidth}px`;
      }
      if (
        props?.style?.borderTopWidth ||
        props?.style?.borderBottomWidth ||
        props?.style?.borderLeftWidth ||
        props?.style?.borderRightWidth
      ) {
        pageSizeBox[0].style.display = "flex";
        pageSizeBox[0].style["justify-content"] = "center";
        pageSizeBox[0].style["align-items"] = "center";
      }
      if (props && props.style && props.style.borderTopWidth) {
        pageSizeBox[0].style["border-top-width"] = `${props.style.borderTopWidth}px`;
      }
      if (props && props.style && props.style.borderBottomWidth) {
        pageSizeBox[0].style["border-bottom-width"] = `${props.style.borderBottomWidth}px`;
      }
      if (props && props.style && props.style.borderLeftWidth) {
        pageSizeBox[0].style["border-left-width"] = `${props.style.borderLeftWidth}px`;
      }
      if (props && props.style && props.style.borderRightWidth) {
        pageSizeBox[0].style["border-right-width"] = `${props.style.borderRightWidth}px`;
      }
      if (props && props.style && props.style.borderStyle) {
        pageSizeBox[0].style["border-style"] = props.style.borderStyle;
      }
      if (
        (props && props.style && props.style.borderTopLeftRadius) ||
        props.style.borderBottomLeftRadius ||
        props.style.borderTopRightRadius ||
        props.style.borderBottomRightRadius
      ) {
        pageSizeBox[0].style["border-radius"] = `${props.style.borderTopLeftRadius ?? 0}px ${
          props.style.borderTopRightRadius ?? 0
        }px ${props.style.borderBottomRightRadius ?? 0}px ${props.style.borderBottomLeftRadius ?? 0}px`;
      }
    }

    let paginationQuickJumperBox: any = (node as Element)?.querySelectorAll(
      "ul > li > .ant-pagination-options-quick-jumper > input"
    );
    if (paginationQuickJumperBox && paginationQuickJumperBox.length && paginationQuickJumperBox.length > 0) {
      if (props && props.style && props.style.borderColor) {
        paginationQuickJumperBox[0].style["border-color"] = props.style.borderColor;
      }
      if (props && props.style && props.style.color) {
        paginationQuickJumperBox[0].style.color = props.style.color;
      }
      if (props && props.style && props.style.borderWidth) {
        paginationQuickJumperBox[0].style["border-width"] = `${props.style.borderWidth}px`;
      }
      if (props && props.style && props.style.borderTopWidth) {
        paginationQuickJumperBox[0].style["border-top-width"] = `${props.style.borderTopWidth}px`;
      }
      if (props && props.style && props.style.borderBottomWidth) {
        paginationQuickJumperBox[0].style["border-bottom-width"] = `${props.style.borderBottomWidth}px`;
      }
      if (props && props.style && props.style.borderLeftWidth) {
        paginationQuickJumperBox[0].style["border-left-width"] = `${props.style.borderLeftWidth}px`;
      }
      if (props && props.style && props.style.borderRightWidth) {
        paginationQuickJumperBox[0].style["border-right-width"] = `${props.style.borderRightWidth}px`;
      }
      if (props && props.style && props.style.borderStyle) {
        paginationQuickJumperBox[0].style["border-style"] = props.style.borderStyle;
      }
      if (
        (props && props.style && props.style.borderTopLeftRadius) ||
        props.style.borderBottomLeftRadius ||
        props.style.borderTopRightRadius ||
        props.style.borderBottomRightRadius
      ) {
        paginationQuickJumperBox[0].style["border-radius"] = `${props.style.borderTopLeftRadius ?? 0}px ${
          props.style.borderTopRightRadius ?? 0
        }px ${props.style.borderBottomRightRadius ?? 0}px ${props.style.borderBottomLeftRadius ?? 0}px`;
      }
    }

    const selectedPageBox: any = (node as Element)?.querySelectorAll(".ant-pagination-item");
    if (selectedPageBox && selectedPageBox.length && selectedPageBox.length > 0) {
      if (props && props.selectedBorderColor) {
        selectedPageBox.forEach((pageBox) => {
          if (pageBox.classList.contains("ant-pagination-item-active")) {
            pageBox.style["border-color"] = props.selectedBorderColor;
          } else {
            pageBox.style["border-color"] = props.style.borderColor ?? "#1890ff";
          }
        });
      }
    }

    const pageBoxesItem: any = (node as Element)?.querySelectorAll("ul > .ant-pagination-item > a");

    if (pageBoxesItem && pageBoxesItem.length && pageBoxesItem.length > 0) {
      pageBoxesItem.forEach((pageBoxesItem) => {
        if (props && props.style && props.style.height) {
          pageBoxesItem.style.removeProperty("height");
        }
      });
    }
  };

  componentDidUpdate = (nextProps: any) => {
    const current = this.props.value;
    if (current) {
      this.setState({ current });
    }

    this.setStyle(nextProps);
  };

  componentDidMount = () => {
    this.setState({ pageSize: this.props.pageSize && this.props.pageSize > 1 ? this.props.pageSize : 10 });
    this.setStyle(this.props);
  };

  getStyleProps = () => {
    let result: any = {};
    if (this.props && this.props.style) {
      result = _.clone(this.props.style);
      if (result) {
        if (this.props.style?.backgroundColor) {
          result.backgroundColor = this.props.style?.backgroundColor;
        }
        if (this.props.style?.backgroundImage) {
          result.backgroundImage = this.props.style?.backgroundImage;
        }
        if (this.props.style?.backgroundRepeat) {
          result.backgroundRepeat = this.props.style?.backgroundRepeat;
        }
        if (this.props.style?.color) {
          result.backgroundRepeat = this.props.style?.color;
        }
      }
    }
    return result;
  };

  getStylePropsForInner = () => {
    const result = this.getStyleProps();
    result.textAlign = "center";
    result.display = "flex";
    return result;
  };

  getStylePropsForButtons = () => {
    const result = this.getStyleProps();
    result.display = "flex";
    result.alignItems = this.props.style?.alignItems;
    result.border = "none";
    result.borderColor = "transparent";
    result.borderRadius = "0px";
    result.borderStyle = "unset";
    result.padding = "0px 5px";
    return result;
  };

  handleFirst = () => {
    this.handleChange(1);
  };

  handlePrevious = () => {
    const current = this.state.current && this.state.current > 1 ? this.state.current : 2;
    this.handleChange(current - 1);
  };

  handleNext = () => {
    const current = this.state.current ? this.state.current : 1;
    this.handleChange(current + 1);
  };

  handleLast = () => {
    if (this.props.total && this.state.pageSize) {
      this.handleChange(Math.ceil(this.props.total / this.state.pageSize));
    }
  };

  firstPageButton = () => {
    const current = this.getCurrentPage();
    if (current <= 1) {
      return <></>;
    }

    return (
      <li title={this.getPaginationTitle("First Page")} tabIndex={0} className="ant-pagination-jump-next">
        <a onClick={this.handleFirst} style={this.getButtonItemStyles()}>
          {this.getFirst()}
        </a>
      </li>
    );
  };

  previousPageButton = () => {
    const current = this.getCurrentPage();
    if (current <= 1) {
      return <></>;
    }
    return (
      <li title={this.getPaginationTitle("Previous Page")} tabIndex={0} className="ant-pagination-jump-next">
        <a onClick={this.handlePrevious} style={this.getButtonItemStyles()}>
          {this.getPrevious()}
        </a>
      </li>
    );
  };

  nextPageButton = () => {
    const total = this.props.total ? this.props.total : 500;
    const pageSize = this.getPageSize();
    const current = this.getCurrentPage();
    if (total / pageSize <= current) {
      return <></>;
    }
    return (
      <li title={this.getPaginationTitle("Next Page")} tabIndex={0} className="ant-pagination-jump-next">
        <a onClick={this.handleNext} style={this.getButtonItemStyles()}>
          {this.getNext()}
        </a>
      </li>
    );
  };

  lastPageButton = () => {
    const total = this.props.total ? this.props.total : 500;
    const pageSize = this.getPageSize();
    const current = this.getCurrentPage();
    if (total / pageSize <= current) {
      return <></>;
    }
    return (
      <li title={this.getPaginationTitle("Last Page")} tabIndex={0} className="ant-pagination-jump-next">
        <a onClick={this.handleLast} style={this.getButtonItemStyles()}>
          {this.getLast()}
        </a>
      </li>
    );
  };

  getPaginationTitle = (title: string) => {
    const language = localStorage.getItem("ml");
    let retValue = "";

    switch (language) {
      case "fr_FR":
        switch (title) {
          case "First Page":
            retValue = "Première Page";
            break;
          case "Previous Page":
            retValue = "Page Précédente";
            break;
          case "Next Page":
            retValue = "Page Suivante";
            break;
          case "Last Page":
            retValue = "Dernière Page";
            break;
          case "First":
            retValue = "Premier";
            break;
          case "Previous":
            retValue = "Précédent";
            break;
          case "Next":
            retValue = "Suivant";
            break;
          case "Last":
            retValue = "Dernier";
            break;
          default:
            retValue = title;
            break;
        }
        break;
      case "tr_TR":
        switch (title) {
          case "First Page":
            retValue = "İlk Sayfa";
            break;
          case "Previous Page":
            retValue = "Önceki Sayfa";
            break;
          case "Next Page":
            retValue = "Sonraki Sayfa";
            break;
          case "Last Page":
            retValue = "Son Sayfa";
            break;
          case "First":
            retValue = "İlk";
            break;
          case "Previous":
            retValue = "Önceki";
            break;
          case "Next":
            retValue = "Sonraki";
            break;
          case "Last":
            retValue = "Son";
            break;
          default:
            retValue = title;
            break;
        }
        break;
      case "en_US":
        retValue = title;
        break;
      default:
        retValue = title;
        break;
    }
    return retValue;
  };

  getPageSizeOptions = () => {
    const options = this.props.options;
    if (options && options.length > 0) {
      const mappedOptions: number[] = [];
      options.forEach((option: any) => {
        const value = option.text?.trim() ?? option[this.props.datavaluefield];
        if (!isNaN(value) && value !== null && value !== undefined && value !== "") {
          mappedOptions.push(parseInt(value));
        }
      });
      mappedOptions?.sort((a, b) => a - b);
      if (mappedOptions.length > 0) return mappedOptions;
    }

    return Pagination.DEFAULT_PAGE_SIZE_OPTIONS;
  };

  render(): ReactNode {
    const total =
      this.props.total !== undefined && this.props.total !== null && this.props.total >= 0 ? this.props.total : 500;
    const pageSize = this.getPageSize();
    const isFirstPageButtonVisible = this.props.showFirstPageButton === true && total > 1;
    const isLastPageButtonVisible = this.props.showLastPageButton === true && total > 1;
    return (
      <>
        <div className={this.props.className} style={{ width: "100%", ...this.getStyleProps(), border: "none" }}>
          <div style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
            {isFirstPageButtonVisible && (
              <ul
                className="ant-pagination"
                style={this.props.style?.alignItems ? this.getStylePropsForButtons() : { padding: "0px 5px" }}
              >
                {this.firstPageButton()}
              </ul>
            )}
            {this.props.showNumbers === false && (
              <ul
                className="ant-pagination"
                style={
                  this.props.style?.alignItems || !this.props.showNumbers
                    ? this.getStylePropsForButtons()
                    : { padding: "0px 5px" }
                }
              >
                {this.previousPageButton()}
                {this.nextPageButton()}
              </ul>
            )}
            {this.props.showNumbers !== false && (
              <AntdPagination
                total={total}
                onChange={this.handleChange}
                onShowSizeChange={this.handleSizeChange}
                style={this.getStylePropsForInner()}
                itemRender={this.itemRender}
                pageSize={pageSize}
                current={this.getCurrentPage()}
                pageSizeOptions={this.getPageSizeOptions()}
                showSizeChanger={this.props.showSizeChanger}
                showQuickJumper={this.props.showQuickJumper}
                showLessItems={this.props.showLessItems}
                className="kuika__pagination"
              ></AntdPagination>
            )}
            {isLastPageButtonVisible && (
              <ul
                className="ant-pagination"
                style={this.props.style?.alignItems ? this.getStylePropsForButtons() : { padding: "0px 5px" }}
              >
                {this.lastPageButton()}
              </ul>
            )}
          </div>
        </div>
      </>
    );
  }
}

const pagination = withCommonEvents(Pagination);
export { pagination as Pagination };
