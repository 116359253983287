import { Guid } from "guid-typescript";
import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KuikaAppManager } from "../../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common/common-props";

declare let window: any;

interface PanelProps {
  style?: CSSProperties;
  scrolling?: "horizontal" | "vertical" | "auto" | "hidden";
  scrollBackColor?: string;
  scrollWidth?: number;
  scrollRadius: boolean;
  dynamicBackGroundColor: string;
  dynamicBgImage: string;
  dynamicFontColor: string;
  hoverBackgroundColor?: string;
  hoverFontColor?: string;
}

interface PanelState {
  uniqueKey: Guid;
}

class Panel extends PureComponent<PanelProps & CommonProps, PanelState> {
  constructor(props: PanelProps) {
    super(props);
    this.state = { uniqueKey: Guid.create() };
  }

  componentDidUpdate(): void {
    this.updateStyle();
  }

  componentDidMount = () => {
    const uniqueKey: string = this.state.uniqueKey.toString();

    if (!uniqueKey) {
      return;
    }

    if (this.props.scrolling) {
      const dynamic_style = document.getElementById("dynamic_style");

      if (!dynamic_style) return;

      if (dynamic_style.innerHTML?.indexOf(uniqueKey) === -1) {
        dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${this.getDynamicCss()}`;
      }
    }

    if (this.props.hoverBackgroundColor) {
      const css = `.${this.getClassName().trim()}:hover{ background-color: ${this.addHashIfNeeded(
        this.props.hoverBackgroundColor
      )} }`;
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(css));
      document.getElementsByTagName("head")[0].appendChild(style);
    }

    if (this.props.hoverFontColor) {
      const css = `.${this.getClassName().trim()}:hover{ color: ${this.addHashIfNeeded(this.props.hoverFontColor)} }`;
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(css));
      document.getElementsByTagName("head")[0].appendChild(style);
    }
    this.updateStyle();
  };

  updateStyle = () => {
    const node: any = ReactDOM.findDOMNode(this);
    let style: React.CSSProperties = _.clone(this.props.style);
    if (this.props?.style?.alignItems !== "flex-start" && node && node.children && node.children.length > 0) {
      KMainFunctions.handleAlignments(node, style);
    }
  };

  addHashIfNeeded = (value: string): string => {
    if (value && value.length === 6 && !value.startsWith("#")) {
      return `#${value}`;
    }
    return value;
  };

  getStyle = (): any => {
    let style: CSSProperties = {};

    if (this.props.style) {
      style = { ...this.props.style };
    }

    if (this.props.scrolling) {
      if (this.props.scrolling === "horizontal") {
        style.overflowX = "auto";
        style.overflowY = "hidden";
      }
      if (this.props.scrolling === "vertical") {
        style.overflowX = "hidden";
        style.overflowY = "auto";
      }
      if (this.props.scrolling === "auto") {
        style.overflow = "auto";
      }
      if (this.props.scrolling === "hidden" && (this.props.style?.height || this.props.style?.maxHeight)) {
        style.overflow = "hidden";
      }
    }

    if (this.props.dynamicBackGroundColor && this.props.dynamicBackGroundColor.length > 0) {
      style.backgroundColor = this.addHashIfNeeded(this.props.dynamicBackGroundColor);
    }

    if (this.props.dynamicBgImage && this.props.dynamicBgImage.length > 0) {
      let bgImage = this.props.dynamicBgImage;
      if (Guid.isGuid(bgImage)) {
        bgImage = `url(${KuikaAppManager.GetBackendUrl()}/resource/runtime/byid?resourceId=${bgImage})`;
      } else if (bgImage.startsWith("/image")) {
        bgImage = `url(${KuikaAppManager.GetBackendUrl()}${bgImage})`;
      } else {
        bgImage = `url(${bgImage})`;
      }
      style.backgroundSize = "cover";
      style.backgroundImage = bgImage;
    }

    if (this.props.dynamicFontColor && this.props.dynamicFontColor.length > 0) {
      style.color = this.addHashIfNeeded(this.props.dynamicFontColor);
    }

    return style;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();

    if (!className || className.length === 0) {
      return "";
    }

    const scrollBackColor = this.getScrollBackColor();
    const scrollRadius = this.getScrollRadius();
    const scrollWidth = this.getScrollWidth();
    let result = "";

    if (scrollWidth) {
      result = `
        .${className}::-webkit-scrollbar {
          ${scrollWidth}
        }`;
    }

    if (scrollBackColor || scrollRadius) {
      result = `${result}.${className}::-webkit-scrollbar-thumb {
          ${scrollBackColor ?? ``}
          ${scrollRadius ?? ``}
        }`;

      result = `${result}.${className}::-webkit-scrollbar-thumb:hover {
          ${scrollBackColor ?? ``}
          ${scrollRadius ?? ``}
        }`;
    }

    return result;
  };

  getScrollBackColor = (): string | undefined => {
    if (this.props.scrollBackColor && this.props.scrollBackColor.length > 0) {
      return `background: ${this.props.scrollBackColor};`;
    }
  };

  getScrollRadius = (): string | undefined => {
    if (this.props.scrollRadius && this.props.scrollRadius === true) {
      return "border-radius: 20px;";
    }
  };

  getScrollWidth = (): string | undefined => {
    if (this.props.scrollWidth && this.props.scrollWidth > 0) {
      return `width: ${this.props.scrollWidth}px;
      height: ${this.props.scrollWidth}px;`;
    }
  };

  getClassName = (): string => {
    let result = "";

    if (this.props.className) {
      result = `${this.props.className} `;
    }

    if (!this.state.uniqueKey) {
      return result;
    }

    result = `${result}panel_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);

    if (props.children) {
      delete props.children;
    }

    return props;
  };

  render(): ReactNode {
    return (
      <div {...this.getProps()} onClick={this.props?.onClick} className={this.getClassName()} style={this.getStyle()}>
        {this.props.children}
      </div>
    );
  }
}

const panel = withCommonEvents(Panel);
export { panel as Panel };
