import _ from "lodash";
import { PureComponent, ReactNode } from "react";

interface TableHeaderRowProps {
  style?: any;
}

interface TableHeaderRowState {}

export class TableHeaderRow extends PureComponent<TableHeaderRowProps, TableHeaderRowState> {
  constructor(props: TableHeaderRowProps) {
    super(props);
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props?.style?.display) {
      delete props.style.display;
    }
    return props;
  };

  render(): ReactNode {
    return <tr {...this.getProps()} />;
  }
}
