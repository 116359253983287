import { PureComponent, ReactNode } from "react";

interface FlexGridBodyCellProps {}

interface FlexGridBodyCellState {}

export class FlexGridBodyCell extends PureComponent<FlexGridBodyCellProps, FlexGridBodyCellState> {
  constructor(props: FlexGridBodyCellProps) {
    super(props);
  }

  render(): ReactNode {
    return <div {...this.props} />;
  }
}
