import { CSSProperties, PureComponent } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common/common-props";

interface ContentMenuPanelProps {
  onMenuClick?: () => void;
  onButtonClick?: () => void;
  imageValue?: string;
  style?: CSSProperties;
  widthType?: "fill" | "content";
  menuOpeningType?: "click" | "hover";
}

interface ContentMenuPanelState {}

class ContentMenuPanel extends PureComponent<ContentMenuPanelProps & CommonProps, ContentMenuPanelState> {
  constructor(props: ContentMenuPanelProps) {
    super(props);
    this.state = {};
  }

  getProps = (): ContentMenuPanelProps & CommonProps => {
    const props = { ...this.props };

    if (props.style) {
      props.style = this.getStyles({ ...props.style });
    }

    return props;
  };

  getStyles = (style: CSSProperties): CSSProperties => {
    style.display = "inline-flex";

    switch (style.textAlign as string) {
      case "center":
      case "-webkit-center":
        style.justifyContent = "center";
        break;
      case "left":
      case "-webkit-left":
        style.justifyContent = "flex-start";
        break;
      case "right":
      case "-webkit-right":
        style.justifyContent = "flex-end";
        break;
    }

    style.alignItems = "center";
    return style;
  };

  render = () => {
    return <div {...this.getProps()}></div>;
  };
}

const contentMenuPanel = withCommonEvents(ContentMenuPanel);
export { contentMenuPanel as ContentMenuPanel };
